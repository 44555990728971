<!-- for desktop -->
<div class="main-desk">
  <div class="section-img">
    <div class="left-side">
      <img _ngcontent-gto-c49="" src="../../../assets/images/logoactive.png">
    </div>
    <div class="right-side">
      <div class="signup-form">
        <!-- <form action="/examples/actions/confirmation.php" method="post">
          <h2>Add Instructor</h2>
          <div class="form-group" id="user-main">
            <input class="form-control" #userName name="userName" placeholder="Username" type="text"
              required="required">
          </div>
          <div class="form-group">
            <input type="email" class="form-control" #email name="email" placeholder="Email" required="required">
          </div>
          <div class="form-group" id="date-main">
            <input class="form-control" #date name="date" placeholder="date" type="date" required="required">
          </div>
          <div class="form-group">
            <input type="password" class="form-control" #password name="password" placeholder="Password"
              required="required">
          </div>

          <div class="form-group text-center ">
            <a type="button" id="save-button" (click)="handleSubmit()" class="mx-auto ">
              Save
            </a>
            <div>
              <a class="back" id="back-button" routerLink="/list-instructors">
                < Back </a>
            </div>
          </div>
        </form> -->
        <form [formGroup]="SignUpForm" (ngSubmit)="handleSubmit()">
          <h2>Add Instructor</h2>
          <div class="form-group" id="user-main">
            <input class="form-control" formControlName="userName" name="userName" placeholder="Name" type="text"
              [ngClass]="{invalid_field:form.userName.invalid && form.userName.touched}">
            <div *ngIf="form.userName.invalid && form.userName.touched" class="invalid-feedback d-block">
              <div *ngIf="form.userName.errors.required" class="invalid-feedback d-block">
                Required
              </div>
            </div>
          </div>

          <div class="form-group">
            <input type="email" class="form-control" formControlName="email" name="email" placeholder="Email"
              [ngClass]="{invalid_field:form.email.invalid && form.email.touched}">
            <div *ngIf="form.email.invalid && form.email.touched" class="invalid-feedback d-block">
              <div *ngIf="form.email.errors.required" class="invalid-feedback d-block">
                Required
              </div>
              <div *ngIf="form.email.errors.email" class="invalid-feedback d-block">
                Incorrect Format
              </div>
            </div>
          </div>

          <div class="form-group" id="date-main">
            <input class="form-control" formControlName="date" name="date" placeholder="date" type="date"
              [ngClass]="{invalid_field:form.date.invalid && form.date.touched}">
            <div *ngIf="form.date.invalid && form.date.touched" class="invalid-feedback d-block">
              <div *ngIf="form.date.errors.required" class="invalid-feedback d-block">
                Required
              </div>
            </div>
          </div>

          <div class="form-group" id="user-main">
            <input type="text" class="form-control" formControlName="wallet" name="wallet" placeholder="wallet"
              [ngClass]="{invalid_field:form.wallet.invalid && form.wallet.touched}">
            <div *ngIf="form.wallet.invalid && form.wallet.touched" class="invalid-feedback d-block">
              <div *ngIf="form.wallet.errors.required" class="invalid-feedback d-block">
                Required
              </div>
            </div>
          </div>

          <div class="form-group text-center ">
            <button type="submit" id="save-button" (click)="handleSubmit()" class="mx-auto ">Save</button>
            <!-- <a type="button" id="save-button" (click)="handleSubmit()" class="mx-auto ">
              Save
            </a> -->
            <div>
              <a class="back" id="back-button" routerLink="/list-instructors">
                < Back </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>