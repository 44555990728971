import { Injectable } from '@angular/core';
// import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {error} from "selenium-webdriver";
import axios from 'axios';
import { isNull } from 'util';



@Injectable()
export class AuthService {
  user;
  constructor(private afAuth: AngularFireAuth ) {
  this.user = this.afAuth.authState;
  console.log("user")
  }
  login() {
    const popup = window.open('https://us-central1-active-alligator.cloudfunctions.net/getEthBalance', '_blank', 'height=700,width=700');
    // const popup = window.open('http://localhost:5001/active-alligator/us-central1/getEthBalance', '_blank', 'height=700,width=700');
  }
  customSignIn(token) {
    localStorage.setItem("AA_COINBASE_ACCESS_TOKEN", token.accessToken)
    return null;
    // return this.afAuth.auth.signInWithCustomToken(token.authTokenToken)
    //   .then((res) => {
    //     window.close()
    //   })
    //   .catch(function (error) {
    //   console.log(error)
    // });
  }

}
