import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-lazy-lamb",
  templateUrl: "./lazy-lamb.component.html",
  styleUrls: ["./lazy-lamb.component.css"],
})
export class LazyLambComponent implements OnInit {
  constructor(
    private router: Router,
    private auctionService: AuctionService,
    private firestore: AngularFirestore
  ) {
  }

  Auctions: any[];
  expiredAuctions: any[];

  showToggle = false;

  ngOnInit(): void {

    this.auctionService.getAuction().subscribe(async (data) => {
      this.Auctions = data.map((e) => {
        const now = new Date().getTime();
        // @ts-ignore
        let sec = e.payload.doc.data().time.seconds * 1000;
        let time = new Date(sec).getTime();
        const distance = time - now;
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {}),
          isActive: distance > 0 ? true : false
        } as unknown as Auction;
      });
      this.getExpiredAuction(this.Auctions);
      this.expiredAuctions = this.Auctions?.filter((el) => !el.isActive);
      console.log('his.Auctions', this.Auctions);
      this.getSponsors();
    });
    if (!localStorage.getItem("userData")) {
      this.showToggle = true;
    } else {
      this.showToggle = false;
    }
  }

  getExpiredAuction(exAuctions) {
    let auctions = [];
    exAuctions?.map((ele) => {
      let splited = this.toDateString(ele.time.seconds * 1000).split(",")
      let date = splited[0].split("/").join("-");
      let time = splited[1].trim();
      auctions.push({
        ...ele, date: date, dateToSort: new Date(date), onlyTime: time
      })
    })
    let sortedAuctions = auctions.sort((a, b) => {
      return b.dateToSort - a.dateToSort;
    });
    this.Auctions = sortedAuctions;
  }

  async getSponsors() {
    for (let item of this.Auctions) {
      item.sponsor = await this.getSponsor(item.bannerId);
    }
  }

  getActiveAuctions() {
    return this.Auctions?.filter((item) => item.isActive);
  }

  toDateString(val: any) {
    return new Date(val).toLocaleString();
  }

  goToAuctionPage(auctionId: string, bid: any) {
    return this.router.navigateByUrl(`/auction/${auctionId}/${bid}`);
  }

  async getSponsor(sponsorId: string) {
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .doc(`sponsors/${sponsorId}`)
          .get()
          .subscribe((data) => {
            if (data) {
              let obj = {};
              obj = data.data();
              resolve({ ...obj, id: data.id });
            }
            resolve(null);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
