import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-experience-rated',
  templateUrl: './experience-rated.component.html',
  styleUrls: ['./experience-rated.component.css']
})
export class ExperienceRatedComponent implements OnInit {

  selectedRating;

  constructor( private _router: Router, private _route: ActivatedRoute, private firestore: AngularFirestore) {
  }

  async ngOnInit() {
    try {
      this._route.paramMap.subscribe(params => {
        this.selectedRating = params.get('rating');
      });
    } catch (err) {
      console.log(err);
    }
  }

}
