<!-- for mobile -->
<div class="container-mobile px-0">
  <div class="images">

    <!-- <img src="../../../assets/SVG-WW/Banner-Op2.svg" class="img-1" alt="image" /> -->
    <div class="main-text1">
      <div class="img-2">
        <!-- <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
        alt="image" /> -->
      </div>
      <span class="main-text">Joe Smith</span>
      <!-- <p>City Fittness</p> -->
    </div>
  </div>
  <div class="text">
    <span class="text-heading">Hello trainer</span>
    <span class="text-paragraph">, your analytics <br> are all set!</span>
  </div>

  <div class="buttons">
    <div>
      <img src="../../../assets/SVG-WW/Monthly_Progress.png" alt="image">
    </div>

    <div>
      <img src="../../../assets/SVG-WW/payment.png" alt="image">
    </div>

    <div (click)="showButtons()">
      <img src="../../../assets/SVG-WW/crypto.png" alt="image">
    </div>
    <div *ngIf="isButtonVisible">
      <div class="cus-button">
        <div class="button-text" (click)="auth.login()">Connect with Ethereum</div>
        <div class="button-icon"><i class="fa fa-cog"></i></div>
      </div>

      <div class="balanc">
        <label>Your balance</label>
        <p>{{wallet.balance.amount}} {{wallet.currency.code}}</p>
      </div>

      <div class="cus-button">
        <div class="button-text" (click)="loginHandle()">Connect with MetaMask</div>
        <div class="button-icon"><i class="fa fa-cog"></i></div>
      </div>

      <div class="balanc">
        <label>Your balance</label>
        <p>{{metmaskBalance}} {{"USD"}}</p>
      </div>

    </div>
    <div (click)="showMagic()">
      <img src="../../../assets/SVG-WW/link.png" (click)="NewGenerateLink(user.uid)" alt="image">
    </div>
    <div *ngIf="isMagicVisible" class=" m-auto col-sm-10 col-md-10 col-lg-10 ">
      <!-- <div class="form-group mb-3">

        <input type="text" class="form-control" #displayName placeholder="Name" value={{user?.displayName}}
               required="required">
      </div>
      <div class="form-group mb-3">

        <input type="text" class="form-control" #email placeholder="Email" value={{user?.email}}
               required="required" readonly>
      </div> -->
      <div class="form-group">
        <!-- <label>Magic Link</label> -->

        <input type="text" class="form-control" placeholder="Magic Link" value={{magicLink}} required="required"
          readonly>

        <span type="button" *ngIf="this.magicLink" (click)="copyMagicLink2()"
          class="ml-auto mt-2 magicbtn float-right btn btn-danger text-white">Copy Magic Link</span>
        <div class="float-left">
          <span type="button" *ngIf="!this.magicLink" (click)="generateLink2()" class=" text-danger  mt-3 "><img
              class="magicbtn1" src="assets/Link.png" /></span>
          <!-- <span type="button" (click)="handleSubmit2()" class="ml-2 mt-2 mb-5"><img class="savebtn" src="assets/Save.png" /></span></div> -->
          <!-- <span> <a class="back" routerLink="/">
                <img class="" src="assets/Back_1.png" />
              </a></span> -->
        </div>
      </div>
    </div>

    <!-- <div (click)="showCard()">
      <img src="../../../assets/SVG-WW/password.png" alt="image">
    </div>
    <div *ngIf="isCardVisible" class=" m-auto col-sm-10 col-md-10 col-lg-10 ">
      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="passwordCredentials.oldPassword" placeholder="Password"
          required="required">
      </div>
      <div class="form-group1">
        <input type="text" class="form-control" [(ngModel)]="newPasswordCredentials.newPassword"
          placeholder="New Password" required="required">
        <label *ngIf="newPasswordCredentials.newPassword.length<6" style="color: red; text-align: left;width: 280px;"
          id="demo1">charaters must me more then 6</label>
      </div>

      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="confirmPasswordCredentials.confirmPassword"
          placeholder="Confirm Password" required="required">

        <span type="button" (click)="resetPassword2()" class="save-btn float-left  mt-3 mb-5">
          Change Password
        </span>
      </div>
    </div> -->


    <div class="click-show col-10 col-sm-12 col-md-12 col-lg-12 mt-4 mx-4" [id]="activeTabId" [ngClass]="
    activeTabId === 'security'
      ? 'tab-pane fade show active'
      : 'tab-pane fade'
      ">

      <!-- <div class="form-group">
        <input type="password" class="form-control" #Password placeholder="New Password" required="required" />
      </div> -->

      <!-- <div class="form-group">
        <input type="password" class="form-control mt-3" #confirmPassword name="confirmPassword"
          placeholder="Confirm Password" required="required" />
        <span class="button" (click)="resetPassword()">
          <img class="saveBtn mt-3 mb-2" src="assets/Password.png" alt="image" /></span>
      </div> -->

    </div>
  </div>
</div>

<!-- for desktop -->
<div class="container-desktop px-0">
  <div class="section-main">
    <!-- <img src="../../../assets/SVG-WW/Banner-Op2.svg" class="img-1" alt="image" /> -->
    <div class="main-content">
      <h1>Joe Smith</h1>
      <!-- <p>City Fittness</p> -->
    </div>
    <div class="image-main">
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
        alt="image" />
    </div>
  </div>

  <div class="trainer-main">
    <div class="text-hello">
      <h1>Hello, <span>your analytics are all set!</span></h1>
    </div>
    <div class="buttons">
      <div class="cards-one">
        <div>
          <img src="../../../assets/SVG-WW/Monthly_Progress.png" alt="image">
        </div>

        <div>
          <img src="../../../assets/SVG-WW/payment.png" alt="image">
        </div>

        <div (click)="showButtons()">
          <img src="../../../assets/SVG-WW/crypto.png" alt="image">
        </div>
      </div>
      <div *ngIf="isButtonVisible" class="conect-img">
        <div class="cus-button">
          <div class="button-text" (click)="auth.login()">Connect with Ethereum</div>
          <div class="button-icon"><i class="fa fa-cog"></i></div>
        </div>

        <div class="balanc">
          <label>Your balance</label>
          <p>{{wallet.balance.amount}} {{wallet.currency.code}}</p>
        </div>

        <div class="cus-button">
          <div class="button-text" (click)="loginHandle()">Connect with MetaMask</div>
          <div class="button-icon"><i class="fa fa-cog"></i></div>
        </div>

        <div class="balanc">
          <label>Your balance</label>
          <p>{{metmaskBalance}} {{"USD"}}</p>
        </div>

      </div>

      <div class="buttons-two">
        <div (click)="showMagic()">
          <img src="../../../assets/SVG-WW/link.png" (click)="NewGenerateLink(user.uid)" alt="image">
        </div>


        <div *ngIf="isMagicVisible" class=" m-auto col-sm-10 col-md-10 col-lg-10 ">
          <!-- <div class="form-group mb-3">

        <input type="text" class="form-control" #displayName placeholder="Name" value={{user?.displayName}}
               required="required">
      </div>
      <div class="form-group mb-3">

        <input type="text" class="form-control" #email placeholder="Email" value={{user?.email}}
               required="required" readonly>
      </div> -->
          <div class="form-group">

            <input type="text" class="form-control" placeholder="Magic Link" value={{magicLink}} required="required"
              readonly>

            <span type="button" *ngIf="this.magicLink" (click)="copyMagicLink2()"
              class="ml-auto mt-2 magicbtn float-right btn btn-danger text-white">Copy Magic Link</span>
            <div class="float-left">
              <span type="button" *ngIf="!this.magicLink" (click)="generateLink2()" class=" text-danger  mt-3 "><img
                  class="magicbtn1" src="assets/Link.png" /></span>
              <!-- <span type="button" (click)="handleSubmit2()" class="ml-2 mt-2 mb-5"><img class="savebtn" src="assets/Save.png" /></span></div> -->
              <!-- <span> <a class="back" routerLink="/">
                <img class="" src="assets/Back_1.png" />
              </a></span> -->
            </div>
          </div>
        </div>

        <!-- <div (click)="showCard()">
          <img src="../../../assets/SVG-WW/password.png" alt="image">
        </div> -->


      </div>

      <!-- <div *ngIf="isCardVisible" id="set-width" class=" m-auto col-sm-10 col-md-10 col-lg-10 ">
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="passwordCredentials.oldPassword" placeholder="Password"
            required="required">
        </div>
        <div class="form-group1">
          <input type="text" class="form-control" [(ngModel)]="newPasswordCredentials.newPassword"
            placeholder="New Password" required="required">
          <label *ngIf="newPasswordCredentials.newPassword.length<6" style="color: red; text-align: left;width: 280px;"
            id="demo1">charaters must me more then 6</label>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="confirmPasswordCredentials.confirmPassword"
            placeholder="Confirm Password" required="required">

          <span type="button" (click)="resetPassword2()" class="save-btn float-left  mt-3 mb-5">
            Change Password
          </span>
        </div>
      </div> -->
      <div class="click-show col-10 col-sm-12 col-md-12 col-lg-12 mt-4 mx-4" [id]="activeTabId" [ngClass]="
    activeTabId === 'security'
      ? 'tab-pane fade show active'
      : 'tab-pane fade'
      ">

      </div>
    </div>
  </div>
</div>