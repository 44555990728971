<!-- <button id="clos-btn" type="button" class="close" (click)="handleClose()" aria-label="Close"> -->
<button id="clos-btn" type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>

<!-- <lib-toastify-toast-container></lib-toastify-toast-container> -->

<div class="px-2 py-4">
  <p>Your bid was the highest one of all the bids on this auction.</p>
  <p>
    Please below your card details then press the submit button to complete the
    payment for the auction.
  </p>
  <p>
    Upon successful payment, you'll be redirected to your bid profile dashboard
    and there you can upload media for the purchased banners.
  </p>

  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <label for="card-info">Card Info</label>
    <div id="form-field">
      <div id="card-info" #cardInfo></div>
      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
    <button style="background-color: #1e948b" id="submit-button" type="submit">
      Pay
    </button>
  </form>
</div>
<!-- <button id="clos-btn" type="button" class="close" (click)="handleClose()" aria-label="Close"> -->
<button id="clos-btn" type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>

<!-- <lib-toastify-toast-container></lib-toastify-toast-container> -->

<div class="px-2 py-4">
  <p>Your bid was the highest one of all the bids on this auction.</p>
  <p>
    Please below your card details then press the submit button to complete the
    payment for the auction.
  </p>
  <p>
    Upon successful payment, you'll be redirected to your bid profile dashboard
    and there you can upload media for the purchased banners.
  </p>

  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <label for="card-info">Card Info</label>
    <div id="form-field">
      <div id="card-info" #cardInfo></div>
      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
    <button style="background-color: #1e948b" id="submit-button" type="submit">
      Pay
    </button>
  </form>
</div>
<!-- <button id="clos-btn" type="button" class="close" (click)="handleClose()" aria-label="Close"> -->
<button id="clos-btn" type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>

<!-- <lib-toastify-toast-container></lib-toastify-toast-container> -->

<div class="px-2 py-4">
  <p>Your bid was the highest one of all the bids on this auction.</p>
  <p>
    Please below your card details then press the submit button to complete the
    payment for the auction.
  </p>
  <p>
    Upon successful payment, you'll be redirected to your bid profile dashboard
    and there you can upload media for the purchased banners.
  </p>

  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <label for="card-info">Card Info</label>
    <div id="form-field">
      <div id="card-info" #cardInfo></div>
      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
    <button style="background-color: #1e948b" id="submit-button" type="submit">
      Pay
    </button>
  </form>
</div>
