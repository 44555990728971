import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-auctions-list",
  templateUrl: "./auctions-list.component.html",
  styleUrls: ["./auctions-list.component.css"],
})
export class AuctionsListComponent implements OnInit {
  constructor(
    private router: Router,
    private auctionService: AuctionService,
    private firestore: AngularFirestore
  ) {}
  Auctions: any[];
  ngOnInit(): void {
    this.auctionService.getAuction().subscribe(async (data) => {
      this.Auctions = data.map((e) => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {}),
        } as unknown as Auction;
      });
      this.getSponsors();
    });
  }

  async getSponsors() {
    for (let item of this.Auctions) {
      item.sponsor = await this.getSponsor(item.bannerId);
    }
  }

  toDateString(val: any) {
    return new Date(val).toLocaleString();
  }
  goToAuctionPage(auctionId: string) {
    return this.router.navigateByUrl(`/auction/${auctionId}`);
  }

  async getSponsor(sponsorId: string) {
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .doc(`sponsors/${sponsorId}`)
          .get()
          .subscribe((data) => {
            if (data) {
              let obj={};
              obj=data.data()
              resolve({ ...obj, id: data.id });
            }
            resolve(null);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
