import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LazyLambRatingComponent } from "./components/lazy-lamb-rating/lazy-lamb-rating.component";
import { ExperienceRatedComponent } from "./components/experience-rated/experience-rated.component";
import { SponsorsComponent } from "./components/sponsors/sponsors.component";
import { WonComponent } from "./components/won/won.component";
import { LostComponent } from "./components/lost/lost.component";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastService, AngularToastifyModule } from "angular-toastify";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { RouterModule } from '@angular/router';

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { environment } from "../environments/environment";
import { ImageComponent } from "./components/sponsors/image/image.component";
import { SignupComponent } from "./components/signup/signup.component";
import { LoginComponent } from "./components/login/login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { InstructorListComponent } from "./components/instructor-list/instructor-list.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AdminLayoutComponent } from "./components/admin-layout/admin-layout.component";
import { IntroComponent } from "./components/intro/intro.component";
import { FlowSucessComponent } from "./components/flow-sucess/flow-sucess.component";
import { SecurityComponent } from "./components/security/security.component";
import { AuthRedirectComponent } from "./components/auth-redirect/auth-redirect.component";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { HttpClientModule } from "@angular/common/http";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AuthService } from "./auth.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MetamaskAppComponent } from "./components/metamask-auth/metamask-app/metamask-app.component";
import { MetamaskProfileComponent } from "./components/metamask-auth/metamask-profile/metamask-profile.component";
import { MetamaskLoginComponent } from "./components/metamask-auth/metamask-login/metamask-login.component";
import { PaymentMethodComponent } from "./components/payment-method/payment-method.component";
import { LazyLambLoginComponent } from "./components/lazy-lamb-login/lazy-lamb-login.component";
import { LazyLambComponent } from "./components/lazy-lamb/lazy-lamb.component";
import { AuctionsListComponent } from "./components/auctions-list/auctions-list.component";
import { BidProfileComponent } from "./components/bid-profile/bid-profile.component";
import { SponsorsAdminComponent } from "./components/sponsors-admin/sponsors-admin.component";
import { SponsorImageComponent } from "./components/sponsors-admin/sponsor-image/sponsor-image.component";
import { MyBannersComponent } from "./components/my-banners/my-banners.component";
import { RegisterComponent } from "./components/register/register.component";
import { ToastrModule } from "ngx-toastr";
import { BundleComponent } from './components/bundle/bundle.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    LazyLambComponent,
    LazyLambLoginComponent,
    LazyLambRatingComponent,
    ExperienceRatedComponent,
    SponsorsComponent,
    WonComponent,
    LostComponent,
    SubscriptionComponent,
    ImageComponent,
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    InstructorListComponent,
    PageNotFoundComponent,
    AdminLayoutComponent,
    IntroComponent,
    FlowSucessComponent,
    SecurityComponent,
    AuthRedirectComponent,
    UserDetailsComponent,
    DashboardComponent,
    MetamaskAppComponent,
    MetamaskProfileComponent,
    MetamaskLoginComponent,
    PaymentMethodComponent,
    LazyLambLoginComponent,
    LazyLambComponent,
    AuctionsListComponent,
    BidProfileComponent,
    SponsorsAdminComponent,
    SponsorImageComponent,
    RegisterComponent,
    MyBannersComponent,
    BundleComponent,
  ],
  imports: [
    // RouterModule,
    // CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ToastrModule.forRoot(), // ToastrModule added
    BrowserModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularToastifyModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],

  providers: [AuthService, ToastService],
  bootstrap: [AppComponent],
  // ...
})
// ...
export class AppModule {}
