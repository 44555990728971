<!-- for mobile layout -->
<div class="mobile-vew">
  <div class="container-fluid m-0 p-0">
    <div class="emp-profile">
      <form>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="bg-img">
              <!-- <img
          src="assets/BK2.png"
          alt="" class="bg-img1" /> -->
              <div class="profile-img">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                  alt=""
                />

                <div class="file btn btn-lg btn-primary">
                  <!-- Change Photo -->
                  <input
                    #imageInput
                    accept="image/*"
                    (change)="onUploadChange($event)"
                    type="file"
                    name="file"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mx-auto mt-n2">
            <!-- <div class="row">
        <div class="col-md-12">
          <div class="profile-img">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
              alt=""/>

            <div class="file btn btn-lg btn-primary">
              Change Photo
              <input #imageInput accept="image/*" (change)="onUploadChange($event)"  type="file" name="file"/>
            </div>
          </div>
        </div>
      </div> -->

            <div class="col-md-12 tab-bg">
              <div class="profile-head">
                <!-- <h5>
              {{user?.displayName}}
            </h5> -->
                <!-- <h6>
              Active Alligator
            </h6> -->
                <!--              <div class="row justify-content-center mt-1 text-center">-->

                <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
                  <div class="col-5">
                    <li
                      class="nav-item"
                      (click)="setActiveTab('personal')"
                      [ngClass]="
                        activeTabId === 'personal'
                          ? 'nav-link active'
                          : 'nav-link'
                      "
                    >
                      <span class="mt-1"
                        ><img class="" src="assets/Profile_icon.png"
                      /></span>
                      <span
                        (click)="setActiveTab('personal')"
                        [ngClass]="
                          activeTabId === 'personal'
                            ? 'nav-link active'
                            : 'nav-link'
                        "
                      >
                        About</span
                      >
                    </li>
                  </div>
                  <div class="col-2">
                    <div class="borderdiv"></div>
                  </div>
                  <!-- <div class="col-5">
                    <li class="nav-item mr-5 mt-1">
                      <span class="mt-2">
                        <img class="" src="assets/Security_1.png" />
                      </span>
                      <span class="mt-1" (click)="setActiveTab('security')"
                        [ngClass]="(activeTabId==='security')?'nav-link active':'nav-link'">
                        Security</span>
                    </li>
                  </div> -->
                </ul>
              </div>
            </div>

            <div class="row justify-content-center text-center">
              <div class="col-md-12"></div>
              <div class="col-md-12 col-11 col-lg-12 col-xl-12 col-sm-12">
                <div class="tab-content profile-tab mt-3" id="myTabContent">
                  <div
                    [ngClass]="
                      activeTabId === 'personal'
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    "
                  >
                    <div class="form-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        #displayName
                        placeholder="Name"
                        value="{{ user?.displayName }}"
                        required="required"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        #email
                        placeholder="Email"
                        value="{{ user?.email }}"
                        required="required"
                        readonly
                      />
                    </div>
                    <div class="form-group">
                      <!-- <input type="text" class="form-control" placeholder="Magic Link" value={{magicLink}}
                        required="required" readonly> -->

                      <!-- <span type="button" *ngIf="this.magicLink" (click)="copyMagicLink()"
                        class="ml-auto mt-2 magicbtn float-right btn btn-danger text-white">Copy Magic Link</span> -->
                      <div class="float-left">
                        <!-- <span type="button" *ngIf="!this.magicLink" (click)="generateLink()"
                          class=" text-danger mt-3 "><img class="" src="assets/Link.png" /></span> -->
                        <span
                          type="button"
                          (click)="handleSubmit()"
                          class="ml-2 mt-2 mb-5"
                          ><img class="savebtn" src="assets/Save.png"
                        /></span>
                      </div>
                      <span> <a class="back" routerLink="/"> </a></span>
                    </div>
                  </div>
                  <!-- <div [ngClass]="(activeTabId==='security')?'tab-pane fade show active':'tab-pane fade'">
                    <div class="form-group">
                      <input type="text" class="form-control" #Password placeholder="Password" required="required">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" #Password placeholder="New Password" required="required">
                    </div>

                    <div class="form-group">
                      <input type="text" class="form-control" ngModel="newPassword" #confirmPassword
                        name="confirmPassword" placeholder="Confirm Password" required="required">
                      <span type="button" (click)="resetPassword()" class="password-btn float-left mt-3 mb-5">
                        Change Password
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row justify-content-center text-center">
        <div class="back-btn text-center">
          <a class="back" routerLink="/">
            <!-- <img class="savebtn mb-3" src="assets/Back_1.png" /> -->
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- for desktop layout -->
<div class="desktop-vew">
  <div class="container-fluid m-0 p-0">
    <div class="emp-profile">
      <form id="space-left">
        <div class="row col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6" id="back-color">
            <div class="bg-img">
              <!-- <img
            src=""
            alt="" class="bg-img1" /> -->
              <div class="profile-img">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                  alt=""
                />

                <div class="file btn btn-lg btn-primary">
                  <!-- Change Photo -->
                  <input
                    #imageInput
                    accept="image/*"
                    (change)="onUploadChange($event)"
                    type="file"
                    name="file"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6" id="back-white">
            <!-- <div class="row">
          <div class="col-md-12">
            <div class="profile-img">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                alt=""/>

              <div class="file btn btn-lg btn-primary">
                Change Photo
                <input #imageInput accept="image/*" (change)="onUploadChange($event)"  type="file" name="file"/>
              </div>
            </div>
          </div>
        </div> -->

            <div class="col-md-12 tab-bg">
              <div class="profile-head">
                <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
                  <div class="col-5">
                    <li class="nav-item col-lg-12 col-sm-12 col-md-5">
                      <span class="mt-1"
                        ><img class="" src="assets/Profile_icon.png"
                      /></span>
                      <span
                        (click)="setActiveTab('personal')"
                        [ngClass]="
                          activeTabId === 'personal'
                            ? 'nav-link active'
                            : 'nav-link'
                        "
                      >
                        About</span
                      >
                    </li>
                  </div>
                </ul>
              </div>
            </div>

            <div class="row justify-content-center text-center">
              <div class="col-md-12"></div>
              <div class="col-md-12 col-12 col-lg-12 col-xl-12 col-sm-12">
                <div class="tab-content profile-tab mt-3" id="myTabContent">
                  <div
                    [ngClass]="
                      activeTabId === 'personal'
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    "
                  >
                  <form [formGroup]="securityForm" (ngSubmit)="handleSubmit()">

                    <div class="form-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        #displayName
                        placeholder="Name"
                        value="{{ user?.userName }}"
                        required="required" formControlName="userName"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        #email
                        placeholder="Email"
                        value="{{ user?.email }}"
                        required="required"
                        formControlName="email"
                        readonly
                      />
                    </div>
                    <div class="form-group">
                      <!-- <input type="text" class="form-control" placeholder="Magic Link" value={{magicLink}}
                        required="required" readonly> -->

                      <!-- <span type="button" *ngIf="this.magicLink" (click)="copyMagicLink()"
                        class="ml-auto mt-2 magicbtn float-right btn btn-danger text-white">Copy Magic Link</span> -->
                      <div class="float-left">
                        <!-- <span type="button" *ngIf="!this.magicLink" (click)="generateLink()"
                          class=" text-danger mt-3 "><img class="" src="assets/Link.png" /></span> -->
                        <span
                          type="button"
                          (click)="handleSubmit()"
                          class="save-btn ml-2 mt-2 mb-5"
                          >Save</span
                        >
                      </div>
                      <span>
                        <a class="back" routerLink="/">
                          <!-- <img class="" src="assets/Back_1.png" /> -->
                        </a></span
                      >
                    </div>

                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row justify-content-center text-center">
        <div class="back-btn text-center">
          <a class="back" routerLink="/">
            <!-- <img class="savebtn mb-3" src="assets/Back_1.png" /> -->
            Back
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
