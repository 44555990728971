<div *ngIf="selectedRating==='awesome'" class="container-fluid">
	<div class="row">
		<div class="col-md-4 mx-auto py-4" style="text-align: center;">
			<img class="my-3" src="assets/images/result/experience_rated.png" />
			<div class="clearfix"></div>
			<div class="divMarkup" style="background: url('assets/images/result/bg_awesome.png') no-repeat; ">
			<img class="mt-3 mb-4" src="assets/images/result/icon_awesome.png" />
			</div>
			<div class="clearfix"></div>
			<img class="img-rating" src="assets/images/result/awesome.png" />
			<div class="clearfix"></div>
			<a routerLink="/sponsors"><img src="assets/next.webp" /></a>
		</div>
	</div>
</div>

<div *ngIf="selectedRating==='active'" class="container-fluid">
	<div class="row">
		<div class="col-md-4 mx-auto py-4 wrapper" style="text-align: center;">
			<img class="my-3" src="assets/images/result/experience_rated.png" />
			<div class="clearfix"></div>
			<div class="divMarkup" style="background: url('assets/images/result/bg_active.png') no-repeat; ">
			<img class="mt-3 mb-4" src="assets/images/result/icon_active.png" />
			</div>
			<div class="clearfix"></div>
			<img class="img-rating" src="assets/images/result/active.png" />
			<div class="clearfix"></div>
			<a routerLink="/sponsors"><img src="assets/next.webp" /></a>
		</div>
	</div>
</div>

<div *ngIf="selectedRating==='average'" class="container-fluid">
	<div class="row">
		<div class="col-md-4 mx-auto py-4 wrapper" style="text-align: center;">
			<img class="my-3" src="assets/images/result/experience_rated.png" />
			<div class="clearfix"></div>
			<div class="divMarkup" style="background: url('assets/images/result/bg_average.png') no-repeat; ">
			<img class="mt-3 mb-4" src="assets/images/result/icon_average.png" />
			</div>
			<div class="clearfix"></div>
			<img class="img-rating" src="assets/images/result/average.png" />
			<div class="clearfix"></div>
			<a routerLink="/sponsors"><img src="assets/next.webp" /></a>
		</div>
	</div>
</div>

