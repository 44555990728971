import { Injectable } from "@angular/core";
import { User } from "src/app/user.model";
import { UserService } from "src/app/user.service";

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, private userService: UserService) {}
  userdata: User[];
  isBidder: any;
  isAdmin: any;
  isInstructor: any;
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let user: any = localStorage.getItem("userData");
    if (user) {
      this.router.navigate(["/profile"]);
      return true 
    } else {
      return true;
    }
    // this.userService.getUser().subscribe(async (data) => {
    //   this.userdata = data.map((e) => {
    //     return {
    //       ...(e.payload.doc.data() as {}),
    //     } as User;
    //   });
    //   console.log(JSON.parse(localStorage.getItem("userData")).email);
    //   console.log(this.userdata);
    //   if (
    //     this.userdata.find(
    //       (el) =>
    //         el.userName.toLowerCase() ==
    //         JSON.parse(localStorage.getItem("userData")).email
    //     )
    //   ) {
    //     let userObj = this.userdata.find(
    //       (el) =>
    //         el.userName.toLowerCase() ==
    //         JSON.parse(localStorage.getItem("userData")).email
    //     );
    //     if (userObj.role == "Bidder") {
    //       alert("i am bidder");
    //       this.isBidder = true;
    //     } else if (userObj.role == "Admin") {
    //       alert("i am Admin");
    //       return this.router.navigate(["/profile"]);
    //     } else if (userObj.role == "Instructor") {
    //       alert("i am Instructor");
    //       this.isInstructor = true;
    //     } else {
    //       alert("invalid");
    //     }
    //   } else {
    //     return true;
    //   }
    // });
  }
}
