<div class="container-fluid px-0">

  <div class="col-md-4 mx-auto ">
    <div class="pt-5">
      <img src=".../../../assets/images/logoactive.png" width="200px" class="d-block mx-auto mt-5   mb-2" />
    </div>
    <div class="signup-form">
      <!-- <form>
        <h2>Login</h2>
        <div class="form-group">
          <input class="form-control" #username name="username" placeholder="Username" type="text" required="required">
        </div>
        <div class="form-group">
          <input class="form-control" #email name="email" placeholder="Email" type="email" required="required">
        </div>
        <div class="form-group">
          <input class="form-control" #date name="date" placeholder="date" type="date" required="required">
        </div>

        <div class="form-group">
          <input type="text" class="form-control" #password name="password" placeholder="Wallet" required="required">
        </div>

        <div class="form-group">
          <div class="login-btn">

            <div class="mb-2 mt-2">
              <a type="button" (click)="handleRegister(username.value,email.value,date.value,password.value)">Register
                to Bid

              </a>
            </div>
            <div class="mb-5">
              <a type="button" class="clr-chng" (click)="handleLogin()">Back to Login Page add


              </a>
            </div>
          </div>
        </div>
      </form> -->
      
      <form [formGroup]="RegisterForm" (ngSubmit)="GetRegisterData()">
        <h2>Login</h2>
        <div class="form-group">
          <input class="form-control" formControlName="userName" name="userName" placeholder="Username" type="text"
            [ngClass]="{invalid_field:form.userName.invalid && form.userName.touched}">
          <div *ngIf="form.userName.invalid && form.userName.touched" class="invalid-feedback d-block">
            <div *ngIf="form.userName.errors.required" class="invalid-feedback d-block">
              Required
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <input class="form-control" formControlName="email" name="email" placeholder="Email" type="text"
            required="required" [ngClass]="{invalid_field:form.email.invalid && form.email.touched}">
          <div *ngIf="form.email.invalid && form.email.touched" class="invalid-feedback d-block">
            <div *ngIf="form.email.errors.required" class="invalid-feedback d-block">
              Required
            </div>
            <div *ngIf="form.email.errors.email" class="invalid-feedback d-block">
              Incorrect Format
            </div>
          </div>
        </div>

        <div class="form-group">
          <input class="form-control" formControlName="date" name="date" placeholder="date" type="date"
            [ngClass]="{invalid_field:form.date.invalid && form.date.touched}">
          <div *ngIf="form.date.invalid && form.date.touched" class="invalid-feedback d-block">
            <div *ngIf="form.date.errors.required" class="invalid-feedback d-block">
              Required
            </div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" formControlName="wallet" name="wallet" placeholder="wallet"
            [ngClass]="{invalid_field:form.wallet.invalid && form.wallet.touched}">
          <div *ngIf="form.wallet.invalid && form.wallet.touched" class="invalid-feedback d-block">
            <div *ngIf="form.wallet.errors.required" class="invalid-feedback d-block">
              Required
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="login-btn">
            <div class="mb-2 mt-2">
              <button type="submit" class="mx-auto " id="save-button" (click)="GetRegisterData()" class="mx-auto ">Save</button>
              <!-- <a type="button" (click)="handleRegister(username.value,email.value,date.value,password.value)">Register
                to Bid
              </a> -->
            </div>
            <div class="mb-5">
              <a type="button" class="clr-chng" routerLink="/">Back to Home 
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>