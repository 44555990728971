// import { AuctionsListComponent } from './components/auctions-list/auctions-list.component';
import { NgModule, Component } from "@angular/core";
import { AuthGuard } from "./auth.guard";
import { Expired } from "./components/expired/expired";
import { FlowSucessComponent } from "./components/flow-sucess/flow-sucess.component";
import { LoginComponent } from "./components/login/login.component";
import { SecurityComponent } from "./components/security/security.component";
import { Routes, RouterModule } from "@angular/router";
import { LazyLambComponent } from "./components/lazy-lamb/lazy-lamb.component";
import { LazyLambLoginComponent } from "./components/lazy-lamb-login/lazy-lamb-login.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { LazyLambRatingComponent } from "./components/lazy-lamb-rating/lazy-lamb-rating.component";
import { ExperienceRatedComponent } from "./components/experience-rated/experience-rated.component";
import { SponsorsComponent } from "./components/sponsors/sponsors.component";
import { WonComponent } from "./components/won/won.component";
import { LostComponent } from "./components/lost/lost.component";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AdminGuard } from "./admin.guard";
import { SignupComponent } from "./components/signup/signup.component";
import { AdminLayoutComponent } from "./components/admin-layout/admin-layout.component";
import { InstructorListComponent } from "./components/instructor-list/instructor-list.component";
import { IntroComponent } from "./components/intro/intro.component";
import { UIDGuard } from "./components/uid.guard";
import { USERGuard } from "./components/USER.guard";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { AuthRedirectComponent } from "./components/auth-redirect/auth-redirect.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MetamaskAppComponent } from "./components/metamask-auth/metamask-app/metamask-app.component";
import { MetamaskProfileComponent } from "./components/metamask-auth/metamask-profile/metamask-profile.component";
import { MetamaskLoginComponent } from "./components/metamask-auth/metamask-login/metamask-login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { PaymentMethodComponent } from "./components/payment-method/payment-method.component";
import { BidProfileComponent } from "./components/bid-profile/bid-profile.component";
import { MyBannersComponent } from "./components/my-banners/my-banners.component";
import { RegisterComponent } from "./components/register/register.component";

const routes: Routes = [
  {
    path: "auction-list",
    component: AdminLayoutComponent,
    children: [{ path: "", component: LazyLambComponent }],
  },
  {
    path: "bidprofile",
    component: BidProfileComponent,
    canActivate: [UIDGuard],
  },
  {
    path: "my-banners",
    component: MyBannersComponent,
  },
  {
    path: "callback",
    component: AuthRedirectComponent,
  },
  {
    path: "redirect",
    component: AuthRedirectComponent,
  },
  {
    path: "sponsors",
    component: SponsorsComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [{ path: "", component: LazyLambComponent }],
  },
  {
    path: "register/:auctionId/:bid",
    canActivate: [AuthGuard],
    component: RegisterComponent,
  },
  {
    path: "register",
    // canActivate: [AuthGuard],
    component: RegisterComponent,
  },
  {
    path: "intro/:id/:code",
    component: IntroComponent,
  },
  // {
  //   path: "login",
  //   canActivate: [AuthGuard],
  //   component: LoginComponent,
  // },
  // {
  //   path: "login/:auctionId/:bid",
  //   canActivate: [AuthGuard],
  //   component: LoginComponent,
  // },

  {
    path: "rating",
    component: LazyLambRatingComponent,
    // canActivate: [UIDGuard],
  },
  {
    path: "expired",
    component: Expired,
    // canActivate: [UIDGuard],
  },
  {
    path: "complete",
    component: FlowSucessComponent,
    // canActivate: [UIDGuard],
  },

  {
    path: "rated/:rating",
    component: ExperienceRatedComponent,
    // canActivate: [UIDGuard],
  },
  {
    path: "won/:rewardCode/:rewardPercentage",
    component: WonComponent,
  },
  {
    path: "lost",
    component: LostComponent,
    // canActivate: [UIDGuard],
  },
  {
    path: "profile",
    component: AdminLayoutComponent,
    children: [{ path: "", component: ProfileComponent }],
    canActivate: [UIDGuard],
  },
  {
    path: "security",
    component: AdminLayoutComponent,
    children: [{ path: "", component: SecurityComponent }],
    canActivate: [UIDGuard],
  },
  {
    path: "list-instructors",
    children: [{ path: "", component: InstructorListComponent }],
    canActivate:[AdminGuard],
  },
  {
    path: "subscribe/:rewardCode/:rewardPercentage",
    component: SubscriptionComponent,
  },
  {
    path: "addUser",
    component: SignupComponent,
    canActivate:[AdminGuard],
  },

  {
    path: "userDetail",
    component: UserDetailsComponent,
  },

  {
    path: "auction/:auctionId/:bid",
    component: AdminLayoutComponent,
    children: [{ path: "", component: DashboardComponent }],
  },

  {
    path: "payment",
    component: PaymentMethodComponent,
  },

  {
    path: "metamask-app",
    component: MetamaskAppComponent,
  },

  {
    path: "metamask-profile",
    component: MetamaskProfileComponent,
  },

  {
    path: "metamask-login",
    component: MetamaskLoginComponent,
  },

  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
  },

  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
