import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-lost',
  templateUrl: './lost.component.html',
  styleUrls: ['./lost.component.css']
})
export class LostComponent implements OnInit {

  @Input() lost;
  @Output() lostClick = new EventEmitter();


  constructor(private router: Router, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    const expiryCode = localStorage.getItem("expiryCode").toString()
    this.expiryCodeUpdate(expiryCode)
  }

  expiryCodeDocs;
  expiryCodeDocId;

  expiryCodeUpdate(data) {
    return new Promise<any>((resolve, reject) => {
        this.firestore.collection('expiryCode', ref => ref.where('expiry_code', '==', data))
          .snapshotChanges().subscribe(data => {
          this.expiryCodeDocs = data;
          for (let expiryCodeDoc of this.expiryCodeDocs) {
            this.expiryCodeDocId = expiryCodeDoc.payload.doc.id
            this.firestore
              .collection('expiryCode')
              .doc(this.expiryCodeDocId)
              .update({expired: true })
              .then(res => {
                resolve(res);
              }, err => reject(err));
          }
        });
      }
    )
  }

  handleLostClick() {
    this.router.navigateByUrl("/complete");
  }
}
