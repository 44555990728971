import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import * as firebase from "firebase";
// import { getUser } from "../../services/firebase.service";
import * as CONSTANTS from "../../constants";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "../../auth.service";
import axios from "axios";
import Web3 from "web3";
import { Auth } from "../metamask-auth/types";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  constructor(private firestore: AngularFirestore, public auth: AuthService, private toastr: ToastrService) {}

  @ViewChild("displayName", { static: true }) displayName: ElementRef;
  @ViewChild("email", { static: true }) email: ElementRef;
  @ViewChild("confirmPassword", { static: true }) confirmPassword: ElementRef;
  @ViewChild("Password", { static: true }) Password: ElementRef;

  @ViewChild("myConfirmPassword", { static: true })
  myConfirmPassword: ElementRef;
  @ViewChild("myPassword", { static: true }) myPassword: ElementRef;

  user;
  magicLink = "";
  activeTabId = "personal";
  wallet;
  metmaskBalance;
  web3: Web3 | undefined = undefined; // Will hold the web3 instance;
  setLoading = false;
  isVisible = false;
  LS_KEY = "login-with-metamask:auth";
  setState: any;
  accessToken = this.auth;
  isAvailable = true;
  isButtonVisible: boolean;
  isMagicVisible: boolean;
  isCardVisible: boolean;
  base64textString;
  passwordCredentials: any = {};
  newPasswordCredentials: any = {};
  confirmPasswordCredentials: any = {};

  async ngOnInit() {
    // console.log(await getUser());
    this.user = JSON.parse(localStorage.getItem("userData"));
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    // this.auth.user.subscribe((res) => {
    //   try {
    //     const user: any = res.toJSON();
    //     if (user) {
    //       axios
    //         .get(`https://api.coinbase.com/v2/accounts`, {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem(
    //               "AA_COINBASE_ACCESS_TOKEN"
    //             )}`,
    //           },
    //         })
    //         .then((response) => {
    //           console.log("response fetched");
    //           this.wallet = response.data.data[0];
    //           console.log(this.wallet);
    //         })
    //         .catch((error) => {
    //           console.log("error in fetching");
    //           console.log(error);
    //         });
    //     }
    //   } catch (ex) {
    //     console.log(ex);
    //   }
    // });
  }

  // added new generator function
  async NewGenerateLink(uid) {
    console.log("generate Link in Profile",uid)
    let magicLink;
    const randomString = Math.random().toString(36).substr(2, 10);
    this.user = JSON.parse(localStorage.getItem("userData"));
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    await this.createExpiryCode({
      expiry_code: randomString,
      expired: false,
      uid,
    });
    magicLink = baseUrl + "/intro/" + uid + "/" + randomString;
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = magicLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  handleAuthenticate = ({
    publicAddress,
    signature,
  }: {
    publicAddress: string;
    signature: string;
  }) =>
    fetch(`https://shrouded-harbor-03730.herokuapp.com/api/auth`, {
      body: JSON.stringify({ publicAddress, signature }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((response) => response.json());

  handleSignMessage = async ({
    publicAddress,
    nonce,
  }: {
    publicAddress: string;
    nonce: string;
  }) => {
    try {
      const signature = await this.web3!.eth.personal.sign(
        `I am signing my one-time nonce: ${nonce}`,
        publicAddress,
        "" // MetaMask will ignore the password argument here
      );

      return { publicAddress, signature };
    } catch (err) {
      throw new Error("You need to sign the message to be able to log in.");
    }
  };

  handleSignup = (publicAddress: string) =>
    fetch(`https://shrouded-harbor-03730.herokuapp.com/api/users`, {
      body: JSON.stringify({ publicAddress }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((response) => response.json());

  handleLoggedIn = (auth: Auth) => {
    localStorage.setItem(this.LS_KEY, JSON.stringify(auth));
    this.setState = auth;
    if (this.setState) {
      this.isVisible = true;
      this.isAvailable = false;
    }
  };

  handleInfo = () => {
    this.web3.eth.getAccounts((error, accounts) => {
      console.log(accounts);
      this.web3.eth
        .getBalance(accounts[0])
        .then((res) => {
          console.log(res);
          this.metmaskBalance = res;
        })
        .catch((err) => {
          console.log(err);
        });
      if (accounts.length === 0) {
        // there is no active accounts in MetaMask
      }
    });
  };

  loginHandle = async () => {
    // Check if MetaMask is installed
    if (!(window as any).ethereum) {
    this.toastr.error('Please install MetaMask first.');
      return;
    }

    if (!this.web3) {
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        this.web3 = new Web3((window as any).ethereum);
      } catch (error) {
    this.toastr.error("You need to allow MetaMask.");

        return;
      }
    }

    const coinbase = await this.web3.eth.getCoinbase();
    if (!coinbase) {
    this.toastr.error("Please activate MetaMask first.");

      return;
    }

    const publicAddress = coinbase.toLowerCase();
    this.setLoading = true;
    // Look if user with current publicAddress is already present on backend
    fetch(
      `https://shrouded-harbor-03730.herokuapp.com/api/users?publicAddress=${publicAddress}`
    )
      .then((response) => response.json())
      // If yes, retrieve it. If no, create it.
      .then((users) =>
        users.length ? users[0] : this.handleSignup(publicAddress)
      )
      // Popup MetaMask confirmation modal to sign message
      .then(this.handleSignMessage)
      // Send signature to backend on the /auth route
      .then(this.handleAuthenticate)
      // Pass accessToken back to parent component (to save it in localStorage)
      .then(this.handleLoggedIn)
      .then(this.handleInfo)
      .catch((err) => {
    this.toastr.error(err);
        this.setLoading = false;
      });
  };

  // resetPassword() {
  //   console.log("resetPassword is working");
  //   const Password = this.Password.nativeElement.value;
  //   const newPassword = this.confirmPassword.nativeElement.value;
  //   const user = firebase.auth().currentUser;
  //   if (Password == newPassword) {
  //     user
  //       .updatePassword(newPassword)
  //       .then(function () {
  //         console.log("password updated successfully");
  //         const x = document.getElementById("security");
  //         if (x.style.display === "none") {
  //           x.style.display = "block";
  //         } else {
  //           x.style.display = "none";
  //         }
  //   this.toastr.success('password updated');

  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   } else {
  //   this.toastr.error('Password does not match');
  //   }
  // }
  copyMagicLink() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.magicLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.magicLink = "";
  }

  showButtons() {
    this.isButtonVisible = !this.isButtonVisible;
  }
  showCard() {
    this.isCardVisible = !this.isCardVisible;
  }
  showMagic() {
    this.isMagicVisible = !this.isMagicVisible;
  }
  async generateLink() {
    const randomString = Math.random().toString(36).substr(2, 10);
    console.log("randomString", randomString);
    this.user = JSON.parse(localStorage.getItem("userData"));
    const uid = this.user?.uid;
    await this.createExpiryCode({
      expiry_code: randomString,
      expired: false,
      uid,
    });
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.magicLink = baseUrl + "/intro/" + this.user?.uid + "/" + randomString;
  }

  async createExpiryCode(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode")
        .add(data)
        .then(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString.push(
      "data:image/png;base64," + btoa(e.target.result)
    );
  }

  // handleSubmit() {
  //   const displayName = this.displayName.nativeElement.value;
  //   const email = this.email.nativeElement.value;
  //   const user = firebase.auth().currentUser;
  //   user
  //     .updateProfile({
  //       displayName,
  //       photoURL: this.base64textString,
  //     })
  //     .then(() => {
  //       // alert("Profile updated successfully");
  //   this.toastr.success('Profile updated successfully');


  //     })
  //     .catch((error) => {
  //       // alert("An error occurred, Try Again");
  //   this.toastr.error('An error occurred, Try Again');
  //       console.log(error);
  //     });
  // }

  // resetPassword2() {
  //   const password = this.newPasswordCredentials;
  //   const newPassword1 = this.confirmPasswordCredentials;
  //   const user = firebase.auth().currentUser;
  //   console.log(password);
  //   console.log(newPassword1);
  //   var newPassword = newPassword1.confirmPassword;
  //   console.log("k", newPassword);

  //   if (
  //     password.newPasswordCredentials == newPassword1.confirmPasswordCredentials
  //   ) {
  //     user
  //       .updatePassword(newPassword)
  //       .then(function () {
  //         console.log("password updated successfully");
  //   this.toastr.success('password updated');
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   } else {
  //     // alert("Password does not match");
  //   this.toastr.error('Password does not match');
  //   }
  // }

  copyMagicLink2() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.magicLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.magicLink = "";
  }

  async generateLink2() {
    const randomString = Math.random().toString(36).substr(2, 10);
    console.log("randomString", randomString);
    this.user = JSON.parse(localStorage.getItem("userData"));
    const uid = this.user?.uid;

    await this.createExpiryCode({
      expiry_code: randomString,
      expired: false,
      uid,
    });
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.magicLink = baseUrl + "/intro/" + this.user?.uid + "/" + randomString;
  }

  async createExpiryCode2(data) {
    console.log("user", data);
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode")
        .add(data)
        .then(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  onUploadChange2(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded2(e) {
    this.base64textString.push(
      "data:image/png;base64," + btoa(e.target.result)
    );
  }

  // handleSubmit2() {
  //   // alert("hi");
  //   const displayName = this.displayName.nativeElement.value;
  //   const email = this.email.nativeElement.value;
  //   // const user = firebase.auth().currentUser;
  //   user
  //     .updateProfile({
  //       displayName,
  //       photoURL: this.base64textString,
  //     })
  //     .then(() => {
  //       // alert("Profile updated successfully");
  //       this.toastr.success('Profile updated successfully');
  //     })
  //     .catch((error) => {
  //       // alert("An error occurred, Try Again");
  //       this.toastr.error('An error occurred, Try Again');
  //       console.log(error);
  //     });
  // }
  setActiveTab(tabId: string) {
    this.activeTabId = tabId;
  }
}
