import {Component, Input, OnInit} from '@angular/core';
import { Auth } from '../types';

// interface JwtDecoded {
//   payload: {
//     id: string;
//     publicAddress: string;
//   };
// }

@Component({
  selector: 'app-metamask-profile',
  templateUrl: './metamask-profile.component.html',
  styleUrls: ['./metamask-profile.component.css']
})
export class MetamaskProfileComponent implements OnInit {
  @Input() data;
  constructor() { }

  auth: Auth;
  setState: any;
  LS_KEY = 'login-with-metamask:auth';

  // accessToken = this.auth;

  ngOnInit(): void {
    console.log('test', this.data );
  }

// const {
//   payload: { publicAddress },
// } = jwtDecode<JwtDecoded>(this.accessToken);

  handleLoggedOut = () => {
    localStorage.removeItem(this.LS_KEY);
    this.auth = undefined;
    this.setState = this.auth;
  }
}
