import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
// import * as firebase from "firebase";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // Guard for user is login or not
    let storedUser = JSON.parse(localStorage.getItem("userData"));
    if (storedUser?.role == "Admin") {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
