<div *ngIf="isAvailable">
  <p>
    Please Click to Login with MetaMask!
  </p>
  <button class="Login-button Login-mm" (click)="loginHandle()">
    Login with MetaMask
<!--    {loading ? 'Loading...' : 'Login with MetaMask'}-->
  </button>
</div>

<div className="Profile" *ngIf="isVisible">
  <p>
    <button (click)="handleLoggedOut()">Logout</button>
  </p>
</div>

