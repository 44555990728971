import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
// import * as firebase from "firebase";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/user.service";
import { ToastrService } from 'ngx-toastr';
// import { AuthFireService } from '../../services/twitter-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("username", { static: true }) username: ElementRef;
  @ViewChild("password", { static: true }) password: ElementRef;
  constructor(
    private router: Router,
    // public authService: AuthFireService,
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private usrSrv: UserService
  ) {}
  auctionId: any;
  bid: any;
  show = false;
  ngOnInit(): void {
    this.auctionId = this.route.snapshot.params.auctionId;
    this.bid = Number(this.route.snapshot.params.bid);
    if (this.auctionId) {
      this.show = true;
    }
  }

  handleSubmit() {
    const username = this.username.nativeElement.value;
    const password = this.password.nativeElement.value;
    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(username, password)
    //   .then(async (data) => {
    //     const users = await this.usrSrv.getUserByEmail(username);
    //     console.log(users, "hh");
    //     if (this.auctionId) {
    //       this.router.navigate([`/auction/${this.auctionId}/${this.bid}`]);
    //     } else {
    //       if (users[0].role !== "Bidder") {
    //         this.router.navigateByUrl("/profile");
    //       } else {
    //         this.router.navigateByUrl("/security");
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // alert(errorMessage);
    //     this.toastr.error(errorMessage);
    //   });
  }
  handleRegister() {
    this.router.navigate([`/register/${this.auctionId}/${this.bid}`]);
  }

  // tryTwitterLogin() {
  //   this.authService.doTwitterLogin()
  //     .then(res => {
  //       this.router.navigate(['/profile']);
  //     });
  // }
}
