import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/user.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/user.model";
// import * as firebase from "firebase";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  RegisterForm: any;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private userService: UserService,
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.RegisterForm = this.formBuilder.group({
      userName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      date: [formatDate(new Date(), "yyyy-MM-dd", "en"), [Validators.required]],
      wallet: [null, [Validators.required]],
    });
  }

  userData: User[];
  auctionId: any;
  bid: any;
  ngOnInit(): void {
    this.auctionId = this.route.snapshot.params.auctionId;
    this.bid = this.route.snapshot.params.bid;
  }
  get form() {
    return this.RegisterForm.controls;
  }

  GetRegisterData() {
    if (this.RegisterForm.valid == false) {
      this.RegisterForm.markAllAsTouched();
      return;
    }
    console.log("Form Values :", this.RegisterForm.value);
    let RegisterObj = {
      userName: this.RegisterForm.value.userName,
      email: this.RegisterForm.value.email,
      date: this.RegisterForm.value.date,
      wallet: this.RegisterForm.value.wallet,
    };
    this.handleRegister(
      RegisterObj.userName,
      RegisterObj.email,
      RegisterObj.date,
      RegisterObj.wallet
    );
  }

  async handleRegister(
    userName: string,
    email: string,
    date: string,
    wallet: string
  ) {
    console.log({ userName, email, date, wallet });
    await this.userService
      .createUser({
        userName: userName,
        email: email,
        date: date,
        wallet: wallet,
        role: "Bidder",
      })
      .catch((err) => this.toastr.error("error occured"))
      .finally(() => {
        console.log("Finaly created : ");
        this.toastr.success("Created Successfully");
        this.router.navigate(["/"]);
      });

    // return this.afAuth.auth
    //   .createUserWithEmailAndPassword(email, wallet)
    //   .then((result) => {
    //     this.toastr.success("You have been successfully registered!");
    //     console.log(result.user);
    //     this.userService.createUser({
    //       userName: userName,
    //       email: email,
    //       date: date,
    //       wallet: wallet,
    //       role: "Bidder",
    //     });

    //     this.router.navigate(['/']);
    //   })
    //   .catch((error) => {
    //     this.toastr.error(error.message);
    //   });
  }
  // handleLogin() {
  //   this.router.navigate(["/login"]);
  // }
}
