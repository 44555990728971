<div class="container-fluid text-center">
  <div class="row">
    <div class="col-md-4 mx-auto px-0">
      <div id="div_top">
        <img src="assets/sponsors/btn_sponsor.png" />
      </div>
      <div
        class="connect-message"
        *ngIf="connectedAccount == undefined && connectedAccount == null"
      >
        <p>Connect your Metamask account</p>
        <div class="">
          <button class="Connect-btn" (click)="connectAccount()">
            connect
          </button>
        </div>
      </div>

      <h5
        class="mt-5"
        *ngIf="MyNFTS?.length > 0 == false && !loadingNFT && connectedAccount"
      >
        No Sponsors NFt Found
      </h5>
      <img
        *ngIf="MyNFTS?.length > 0 == false && loadingNFT"
        class="loaderImage"
        src="./../../../../assets/Loader1.gif"
        width="50"
        height="50"
        alt="Loading"
      />
      <div id="content" class="mt-2">
        <app-image
          *ngFor="let nft of MyNFTS"
          [imageUrl]="nft.image"
          (click)="GiftNFT(nft)"
        >
        </app-image>
      </div>
      <!-- <div id="content" class="mt-2">
        <app-image
        *ngFor="let item of sponsors"
        [score]=item.score[0].score
        [total]=item.score[0].total?item.score[0].total:10
        [imageUrl]=item.score[0].imageUrl?item.score[0].imageUrl:item.payload.doc.data().imageUrl
        (click)="GiftNFT(item)">
      </app-image>
      </div> -->
    </div>
  </div>
</div>
