<div class="container-fluid px-0">
	<div class="">
		<div class="col-md-4 px-0 col-sm-12 mx-auto pb-4 wrapper" style="text-align: center;">
			<div class="header">
				<img class="my-3" src="assets/images/rating/rate_your_experience.png" />
			</div>
			<div class="clearfix"></div>
			<div class="divMarkup">
				<div class="mt-3" *ngFor="let item of rating" (click)="selectRatinghandler(item)">
					<img [src]="item?.logo"/>
				</div>
			</div>
		</div>
	</div>
</div>
