
<!-- for desktop -->

<div class="main-desk">
  <div class="section-img">
    <div class="left-side">
    <img src="../../../assets/images/logoactive.png"/>
  </div>
    <div class="right-side">
      <div class="signup-form">
        <form>
          <h1>Login</h1>
          <div class="form-group" id="user">
            <input class="form-control" #username name="username" placeholder="Username" type="text"
                   required="required">
          </div>
          <div class="form-group" id="user"> 
            <input type="password" class="form-control" #password name="password" placeholder="Password"
                   required="required">
          </div>
          <div class="form-group" >
            <div class="login-btn">
              <div class="mb-2 mt-3" id="log-btn">
                <a type="button" (click)="handleSubmit()">Login
                </a>
  
              </div>
              <div class="mb-5">
                <a *ngIf="show" type="button" style="color:#e54728 ;" (click)="handleRegister()">Register to Bid
                </a>
              </div>
  
            </div>
          </div>
  
          <!-- <div class="container">
            <div>
                <button type="button" style='width: 200px; margin: 0 auto;' class="btn btn-info btn-block" (click)="tryTwitterLogin()">Login with Twitter</button>
            </div>
          </div> -->
        </form>
      </div>
  </div>
  </div>
  