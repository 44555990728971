<app-admin-layout></app-admin-layout>

<div class="container-fluid text-center">
  <div class="row">
    <div class="col-md-4 mx-auto px-0">
      <div id="div_top">
        <img src="assets/sponsors/btn_sponsor.png" />
      </div>
      <div id="content" class="mt-2">
        <sponsor-image
          *ngFor="let item of sponsors"
          [score]="item.score[0]?.score"
          [total]="item.score[0]?.total ? item.score[0].total : 10"
          [imageUrl]="getImageUrl(item)"
          [sponsorId]="item.payload.doc.id"
        >
        </sponsor-image>
      </div>
    </div>
  </div>
</div>
