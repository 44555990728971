
<app-admin-layout></app-admin-layout>
<div class="container" id="top-space">
  <div class="insutrctor-text mb-3 float-left">Insutructor-list</div>
  <button type="button" class="add-button btn btn-primary mb-3 float-right" (click)="NavigateAddUser()">Add User</button>
  <div class="clearfix"></div>
<div class="card shadow">
<div class="table-responsive">
  <table class="table table-striped" style="width: 100%">
    <thead>
    <tr>
      <th scope="col">Email</th>
      <th scope="col">Account Verified</th>
      <th scope="col">Magic Links</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td>
        {{ user.email }}
      </td>
      <td>{{ user.emailVerified?'Yes':'No'}}</td>
      <td>{{ user.magicLinksCounts}}</td>
      <td>
        <button type="button"class="delete-button btn btn-danger mr-2" (click)="deleteUser(user.uid)">Delete</button>
        <span type="button" (click)="generateLink(user.uid)"  class="magic-link-btn text-danger mt-2 "><img class="" src="assets/Link.png" /></span>
      </td>

    </tr>
    </tbody>
  </table>
</div>
</div>
</div>

