import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
  selector: 'app-won',
  templateUrl: './won.component.html',
  styleUrls: ['./won.component.css']
})
export class WonComponent implements OnInit {

  rewardCode;
  rewardPercentage;
  constructor(private router: Router, private route: ActivatedRoute, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    ;
    this.rewardCode = this.route.snapshot.params.rewardCode;
    this.rewardPercentage = this.route.snapshot.params.rewardPercentage;
    const expiryCode = localStorage.getItem("expiryCode")
    this.expiryCodeUpdate(expiryCode)
  }


  expiryCodeDocs;
  expiryCodeDocId;


  expiryCodeUpdate(data) {
    return new Promise<any>((resolve, reject) => {
        this.firestore.collection('expiryCode', ref => ref.where('expiry_code', '==', data))
          .snapshotChanges().subscribe(data => {
            ;
          this.expiryCodeDocs = data;
          for (let expiryCodeDoc of this.expiryCodeDocs) {
            this.expiryCodeDocId = expiryCodeDoc.payload.doc.id
            this.firestore
              .collection('expiryCode')
              .doc(this.expiryCodeDocId)
              .update({expired: true })
              .then(res => {
                resolve(res);
              }, err => reject(err));
          }
        });
      }
    )
  }


  handleWonClick() {
    this.router.navigateByUrl(`/subscribe/${this.rewardCode}/${this.rewardPercentage}`);
  }

}
