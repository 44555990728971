<div class="container-fluid px-0">

  <div class="col-md-4 mx-auto ">
    <div class="pt-5">
      <img src="../../../assets/images/logo.png" width="200px" class="d-block mx-auto mt-5   mb-2" /></div>
    <div class="signup-form">
      <form>
        <h2>Forgot Password</h2>
        <div class="form-group">
          <input class="form-control" #email name="email" placeholder="Email" type="text"
                 required="required">
        </div>
        <div class="row pr-5 mr-3 " >
          <div class="col-3 mx-auto">
            <div class="mb-5 mt-3">
              <button class="custom-btn btn submit-btn" type="button" (click)="handleSubmit()">Submit</button></div>
          </div>
        </div>
        <div class="row  pr-5 mr-3 mb-4">
          <div class="col-2 mx-auto mt-5">
            <div class="mt-5 ">
              <a class="back" routerLink="/login">
                <img class="" src="assets/Back_1.png" />
              </a>
            </div>
          </div>
        </div>
        <!--        <div class="text-center">Forgot Password?</div>-->
      </form>
    </div>
  </div>

</div>

<!--<div class="row no-gutters">-->
<!--  <div class="col-md-4 mx-auto">-->
<!--<div class="signup-form">-->
<!--    <form action="" method="post">-->
<!--		<h2>Enter Email</h2>-->
<!--        <div class="form-group">-->
<!--        	<input type="email" class="form-control"#email name="email" placeholder="Email" required="required">-->
<!--        </div>-->
<!--		<div class="form-group">-->
<!--            <button type="button" (click)="handleSubmit()" class="btn btn-secondary">Submit</button>-->
<!--        </div>-->

<!--    </form>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

