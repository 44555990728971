import { Component, OnInit } from '@angular/core';
import Web3 from 'web3';
import { Auth } from '../types';

interface Props {
  onLoggedIn: (auth: Auth) => void;
}

@Component({
  selector: 'app-metamask-login',
  templateUrl: './metamask-login.component.html',
  styleUrls: ['./metamask-login.component.css']
})
export class MetamaskLoginComponent implements OnInit {
  web3: Web3 | undefined = undefined; // Will hold the web3 instance;
  setLoading = false;
  isVisible = false;
  LS_KEY = 'login-with-metamask:auth';
  setState: any;
  auth: Auth;
  // accessToken = this.auth;
  isAvailable = true;
  // loading = false
  constructor() { }

  ngOnInit(): void {
  }

  handleLoggedIn = (auth: Auth) => {
    localStorage.setItem(this.LS_KEY, JSON.stringify(auth));
    this.setState = auth ;
    if (this.setState) {
      this.isVisible = true;
      this.isAvailable = false;
    }
  }

  handleAuthenticate = ({
                          publicAddress,
                          signature,
                        }: {
    publicAddress: string;
    signature: string;
  }) =>
    fetch(`https://shrouded-harbor-03730.herokuapp.com/api/auth`, {
      body: JSON.stringify({ publicAddress, signature }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((response) => response.json())

  handleSignMessage = async ({
                                     publicAddress,
                                     nonce,
                                   }: {
    publicAddress: string;
    nonce: string;
  }) => {
    try {
      const signature = await this.web3!.eth.personal.sign(
        `I am signing my one-time nonce: ${nonce}`,
        publicAddress,
        '' // MetaMask will ignore the password argument here
      );

      return { publicAddress, signature };
    } catch (err) {
      throw new Error(
        'You need to sign the message to be able to log in.'
      );
    }
  }

  handleSignup = (publicAddress: string) =>
    fetch(`https://shrouded-harbor-03730.herokuapp.com/api/users`, {
      body: JSON.stringify({ publicAddress }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((response) => response.json())

  loginHandle = async () => {
    // Check if MetaMask is installed
    if (!(window as any).ethereum) {
      window.alert('Please install MetaMask first.');
      return;
    }

    if (!this.web3) {
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        this.web3 = new Web3((window as any).ethereum);
      } catch (error) {
        window.alert('You need to allow MetaMask.');
        return;
      }
    }

    const coinbase = await this.web3.eth.getCoinbase();
    if (!coinbase) {
      window.alert('Please activate MetaMask first.');
      return;
    }

    const publicAddress = coinbase.toLowerCase();
    this.setLoading = true;
    // Look if user with current publicAddress is already present on backend
    fetch(
      `https://shrouded-harbor-03730.herokuapp.com/api/users?publicAddress=${publicAddress}`
    )
      .then((response) => response.json())
      // If yes, retrieve it. If no, create it.
      .then((users) =>
        users.length ? users[0] : this.handleSignup(publicAddress)
      )
      // Popup MetaMask confirmation modal to sign message
      .then(this.handleSignMessage)
      // Send signature to backend on the /auth route
      .then(this.handleAuthenticate)
      // Pass accessToken back to parent component (to save it in localStorage)
      .then(this.handleLoggedIn).then(this.handleInfo)
      .catch((err) => {
        window.alert(err);
        this.setLoading = false;
      });
  }

  handleInfo = () => {
    this.web3.eth.getAccounts((error, accounts) => {
      console.log(accounts);
      this.web3.eth.getBalance(accounts[0])
        .then(console.log);
      if (accounts.length === 0) {
        // there is no active accounts in MetaMask
      }
      else {
        // It's ok
      }
    });
  }
  handleLoggedOut = () => {
    localStorage.removeItem(this.LS_KEY);
    this.auth = undefined;
    this.setState = this.auth;
    this.isAvailable = true;
    this.isVisible = false;
  }

}
