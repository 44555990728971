import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
// import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UIDGuard implements CanActivate {
  constructor(public router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // Guard for user is login or not
    if (!localStorage.getItem('userData')) {
      this.router.navigate(['/']);
      return true;
    }
    return true;
  }
}
