import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {

  @Input() score = '';
  @Input() total = '';
  @Input() imageUrl = '';

  constructor() { }

  ngOnInit(): void {
  }


}
