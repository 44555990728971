<div class="container-fluid">
  <div class="expired">
    <div class="message">
      <h1>Oops, It seems like you have used this Magic link</h1>
      <p>Your Magic link is not valid anymore</p>
    </div>

    <!--   <div class="light">
        <span></span>
        <span></span>
        <span></span>
      </div>
        <div class="light_btm">
        <span></span>
        <span></span>
        <span></span>
      </div> -->
  </div>
</div>
