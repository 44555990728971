<app-admin-layout></app-admin-layout>
<div id="mobile" class="container-fluid text-center">
  <div class="row">
    <div class="col-md-4 mx-auto px-0">
      <div id="div_top">
        <img src="assets/sponsors/btn_sponsor.png" />
      </div>
      <div id="content" class="mt-2">
        <sponsor-image
          *ngFor="let item of sponsors"
          [imageUrl]="getImageUrl(item)"
          [sponsorId]="item.id"
        >
        </sponsor-image>
      </div>
      <!-- <div id="content" class="mt-2">
        <sponsor-image
          *ngFor="let item of sponsors"
          [score]="item.instructorScores[0]?.score"
          [total]="
            item.instructorScores[0]?.total
              ? item.instructorScores[0].total
              : 10
          "
          [imageUrl]="getImageUrl(item)"
          [sponsorId]="item.id"
        >
        </sponsor-image>
      </div> -->
    </div>
  </div>
</div>

<!-- for desktop -->
<div id="desktop" class="container-fluid text-center">
  <div class="row">
    <div class="col-md-12 mx-auto px-0">
      <div id="div_top">
        <!-- <img src="assets/sponsors/btn_sponsor.png" /> -->
        <div class="circle-text">
          SPONSORS
          <span> <i class="arrow down"></i></span>
        </div>
      </div>
      <div id="content" class="col-md-12 mt-2 d-flex set-style">
        <sponsor-image
          *ngFor="let item of sponsors"
          [score]="item.instructorScores[0]?.score"
          [total]="
            item.instructorScores[0]?.total
              ? item.instructorScores[0].total
              : 10
          "
          [imageUrl]="getImageUrl(item)"
          [sponsorId]="item.id"
        >
        </sponsor-image>
      </div>
    </div>
  </div>
</div>
