<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 mx-auto py-4 px-0" style="text-align: center;">
      <img src="assets/won/Rewards.png" />
      <div class="clearfix"></div>
      <div class="divMarkup">
        <img class="mt-5 mb-5 img" src="assets/images/result/Winer.svg" />
      </div>
      <div class="clearfix"></div>

      <!-- voucher -->
<!--      <div class="divVoucher">-->
<!--        <div class="content">-->
<!--          <span class="labelTop">REWARD</span>-->
<!--          <div class="divPercentage" style="height: 70px;">-->
<!--            <span class="lblPercentage">{{rewardPercentage}}</span>-->
<!--            <span class="percentage">%</span>-->
<!--            <span class="lblOff">OFF</span>-->
<!--            <h1>-->
<!--              YOUR ORDER-->
<!--            </h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- end voucher -->

<!-- reward code -->
<div class="divRewardCode mt-2" (click)="handleWonClick()">
  REWARD CODE: {{rewardCode}}
</div>
<!-- end reward code -->

    </div>
  </div>
</div>
