import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { User } from "src/app/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private firestore: AngularFirestore, private router: Router) { }
  async createUser(user: User) {
    await this.firestore.collection("User").add(user);
  }

  getUser() {
    return this.firestore.collection("User").snapshotChanges();
  }

  getUserByEmail(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("User", (ref) => ref.where("email", "==", email))
        // .select()
        .get()
        .subscribe((doc: any) => {
          resolve(
            doc.docs.map((snap: any) => {
              return { ...snap.data(), id: snap.id };
            })
          );
        });
    });
  }

  getUserByWalletAddress(walletAdress: string) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("User", (ref) => ref.where("wallet", "==", walletAdress))
        // .select()
        .get()
        .subscribe((doc: any) => {
          resolve(
            doc.docs.map((snap: any) => {
              return { ...snap.data(), id: snap.id };
            })
          );
        });
    });
  }

  getUserByID(Id) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("User")
        .doc(Id)
        .get()
        .subscribe(doc => {
          if (!doc.exists) {
            console.log("No documents found");
            resolve(null);
          } else {
            let data=doc.data();
            let id = { id: doc.id };
            Object.assign(data, id);
            console.log("what is in the data L ",data);
            resolve(data)
          }
        });
    });
  }

  async updateUser(user) {
    let updateUser=JSON.parse(JSON.stringify(user))
    delete updateUser.id;
    const userId = user.id;
    await this.firestore.doc("User/" + userId).update(updateUser);
  }
}
