import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {take} from "rxjs/operators";

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  id = '';
  expiryCode='';

  constructor(private router: Router,private route: ActivatedRoute, private firestore: AngularFirestore ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.expiryCode = this.route.snapshot.paramMap.get('code');
    localStorage.setItem('expiryCode', this.expiryCode)
    localStorage.setItem('uid', this.id);
  }

  expiryCodeDocs;
  expiryCodeDocId;

  ngOnInit(): void {
    const docId = localStorage.getItem("expiryCode")
    this.expireCodeCheck(docId)
  }

  expireCodeCheck(docId) {
    return new Promise<any>((resolve, reject) => {
        this.firestore.collection('expiryCode', ref => ref.where('expiry_code', '==', docId))
          .valueChanges().pipe(take(1)).subscribe(data => {
          ;
            if(data.length == 0){
              this.router.navigateByUrl('/expired');
            }
            else {
            this.expiryCodeDocId = data[0];
            ;
            if (this.expiryCodeDocId.expired == true) {
              this.router.navigateByUrl('/expired');
            }
          }
        });
      }
    )
  }

}
