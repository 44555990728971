import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";

// import {USER} from "../../constants";
import { take } from "rxjs/operators";

@Component({
  selector: "app-sponsors-admin",
  templateUrl: "./sponsors-admin.component.html",
  styleUrls: ["./sponsors-admin.component.css"],
})
export class SponsorsAdminComponent implements OnInit {
  instructor;
  sponsors;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) {
    const uid = "goD7oB3wTjWbW78UXw3q0pP8vqz1"; //localStorage.getItem('uid');
    this.firestore
      .collection("sponsors")
      .snapshotChanges()
      .subscribe((data) => {
        this.sponsors = data;
        for (let sponsor of this.sponsors) {
          sponsor.score = sponsor.payload.doc
            .data()
            ["instructorScores"].filter((obj) => {
              return {
                uid: obj.uid,
                bannerId: obj.uid,
                bannerImage: obj.image,
              };
            });
        }
        console.log("sponsors", this.sponsors);
        // console.log("sponsors", this.sponsors);
      });
  }

  expiryCodeDocId;

  ngOnInit(): void {
    // const docId = localStorage.getItem("expiryCode")
    // this.expireCodeCheck(docId)
    // this.instructor = this.route.snapshot.params.instructorId;
    // alert(this.instructor);
  }

  expireCodeCheck(docId) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode", (ref) =>
          ref.where("expiry_code", "==", docId)
        )
        .valueChanges()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length == 0) {
            this.router.navigateByUrl("/complete");
          } else {
            this.expiryCodeDocId = data[0];
            if (this.expiryCodeDocId.expired == true) {
              this.router.navigateByUrl("/expired");
            }
          }
        });
    });
  }

  getImageUrl(item) {
    const data = item.payload.doc.data();
    if (data.auctions) {
      let paidAuctions = data.auctions.filter((f) => f.isPaid);
      paidAuctions.sort((a, b) => {
        return a.paidOn.seconds - b.paidOn.seconds;
      });
      if (paidAuctions && paidAuctions.length) {
        return paidAuctions[0].image;
      }
    }
    return item.score[0].imageUrl
      ? item.score[0].imageUrl
      : item.payload.doc.data()?.imageUrl;
  }

  handleSponsorClick(item) {
    const score = item.score[0].score;
    const rewardPercentage = item.payload.doc.data().rewardPercentage;
    const rewardCode = item.payload.doc.data().rewardCode;
    const instructorScore = item.payload.doc.data().instructorScores;
    console.log("instructo store" + JSON.stringify(instructorScore));
    if (score > 0) {
      const uid = localStorage.getItem("uid");
      let sponsorIndex = instructorScore.findIndex((obj) => obj.uid == uid);
      console.log(
        "this is the index" + JSON.stringify(instructorScore[sponsorIndex])
      );
      instructorScore[sponsorIndex].score = --instructorScore[sponsorIndex]
        .score;
      return new Promise<any>((resolve, reject) => {
        this.firestore
          .collection("sponsors")
          .doc(`${item.payload.doc.id}`)
          .update({ instructorScores: instructorScore })
          .then(
            (res) => {
              this.router.navigateByUrl(
                `/won/${rewardCode}/${rewardPercentage}`
              );
              // resolve();
            },
            (err) => reject(err)
          );
      });
    } else {
      this.router.navigateByUrl(`/lost`);
    }
  }
}
