<div class="container">
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
    "
  >
    <div
      class="divCard display gx-5 mt-lg-4 mb-lg-4 mb-2 mt-2"
      *ngIf="imageUrl !== 'none'"
    >
      <img class="image-style" src="{{ imageUrl }}" alt="Snow" />
      <!-- <span class="lblScore">{{ score }}/{{ total }} </span> -->
    </div>
    <div>
      <i
        class="fa fa-plus-circle copy"
        (click)="create()"
        title="Create Auction"
      ></i>
    </div>
    <div class="form-group" *ngIf="this.todo">
      <!-- <label>Magic Link</label> -->

      <input
        type="text"
        class="form-control"
        *ngIf="this.doto"
        placeholder="Magic Link"
        value="{{ magicLink }}"
        required="required"
        readonly
      />

      <span
        type="button"
        *ngIf="this.magicLink"
        (click)="copyMagicLink2()"
        class="clr-chng ml-auto mt-2 magicbtn float-right btn btn-danger text-white"
        >Copy Magic Link</span
      >
      <div class="float-left">
        <span
          type="button"
          *ngIf="!this.magicLink"
          (click)="generateLink2()"
          class="text-danger mt-3"
          ><img class="magicbtn1" src="assets/Link.png" />
        </span>

        <!-- <span type="button" (click)="handleSubmit2()" class="ml-2 mt-2 mb-5"><img class="savebtn" src="assets/Save.png" /></span></div> -->
        <!-- <span> <a class="back" routerLink="/">
                <img class="" src="assets/Back_1.png" /> 
              </a></span> -->
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
</div>
