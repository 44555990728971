import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
// import * as firebase from 'firebase';
import {Router} from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  @ViewChild('email', {static: true}) email: ElementRef;

  constructor(private router: Router, private firestore: AngularFirestore) { }

  ngOnInit(): void {
  }

  handleSubmit() {
    const email = this.email.nativeElement.value;
    // firebase.auth().sendPasswordResetEmail(email)
    //   .then((data) => {
    //     alert('Email sent successfully! Please check your email');
    //     this.router.navigateByUrl('/login');
    //   })
    //   .catch( (error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     alert(errorMessage);
    //   });
    console.log(email);
  }
}
