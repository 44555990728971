import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.css']
})
export class BundleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
