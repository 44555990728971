import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Auction } from "src/app/auction.model";

@Injectable({
  providedIn: "root",
})
export class AuctionService {
  constructor(private firestore: AngularFirestore) {}

  async createAuction(auction: Auction) {
    return await this.firestore.collection("auctions").add(auction);
  }
  getAuction() {
    return this.firestore.collection("auctions").snapshotChanges();
  }
  async getAuctionById(auctionId: any) {
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .doc(`auctions/${auctionId}`)
          .get()
          .subscribe((data) => {
            if (data) {
              let obj={};
              obj=data.data();
              resolve({ ...obj, id: data.id });
            }
            resolve(null);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }
  async updateAuction(auctionId: string, auction: Auction) {
    delete auction.id;
    await this.firestore.doc("auctions/" + auctionId).update(auction);
  }
  getAuctionByUser(uid: string) {
    return this.firestore.collection("auctions").doc(uid).snapshotChanges();
  }

  async getSponsor(sponsorId: string) {
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .doc(`sponsors/${sponsorId}`)
          .get()
          .subscribe((data) => {
            if (data) {
              // resolve({ ...data.data(), id: data.id });
            }
            resolve(null);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }
  async updateImage(auctionId, imageUrl, rewardCode, rewardPercentage, quantity) {
    await this.firestore
      .doc("auctions/" + auctionId)
      .update({ imageUrl, rewardCode, rewardPercentage, quantity });
  }
}
