import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from '../../auth.service';
import {switchMap, tap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-auth-redirect',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.css']
})
export class AuthRedirectComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private auth: AuthService) {
  }

  ngOnInit(): void {

    try {
      const code = this.route.snapshot.queryParamMap.get('code')

      if (code) {
        console.log('here is code', code);
        // alert(code);
        const url = `https://us-central1-active-alligator.cloudfunctions.net/token?code=${code}`;
        // const url = `http://localhost:5001/active-alligator/us-central1/token?code=${code}`;
        this.http.get(url).pipe(
          switchMap((res:any) => fromPromise(this.auth.customSignIn(res)))
        )
          .subscribe();
      }
    } catch (err) {
      alert(err)
      console.log(err);
    }
  }
}
