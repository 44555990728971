import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {take} from "rxjs/operators";

@Component({
  selector: 'app-lazy-lamb-rating',
  templateUrl: './lazy-lamb-rating.component.html',
  styleUrls: ['./lazy-lamb-rating.component.css']
})
export class LazyLambRatingComponent implements OnInit {

  rating = [
    {
      label: 'awesome',
      logo: 'assets/images/rating/awesome.png'
    },
    {
      label: 'active',
      logo: 'assets/images/rating/active.png'
    },
    {
      label: 'average',
      logo: 'assets/images/rating/average.png'
    }
  ];

  constructor(private router: Router, private firestore: AngularFirestore) {
  }

  expiryCodeDocId;

  ngOnInit(): void {
    const docId = localStorage.getItem("expiryCode")
    this.expireCodeCheck(docId)
  }

  expireCodeCheck(docId) {
    return new Promise<any>((resolve, reject) => {
        this.firestore.collection('expiryCode', ref => ref.where('expiry_code', '==', docId))
          .valueChanges().pipe(take(1)).subscribe(data => {
          ;
          if(data.length == 0){
            this.router.navigateByUrl('/expired');
          }
          else {
            this.expiryCodeDocId = data[0];
            ;
            if (this.expiryCodeDocId.expired == true) {
              this.router.navigateByUrl('/expired');
            }
          }
        });
      }
    )
  }


  selectRatinghandler({label}) {
    // this.updateAllFeedbacks();
    this.createFeedback({rating: label,uid:localStorage.getItem('uid')});
    this.router.navigate([`/rated`, label]);
  }

  createFeedback(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('feedback')
        .add(data)
        .then(res => {
          localStorage.setItem('FeedbackID', res.id);
        }, err => reject(err));
    });
  }

}
