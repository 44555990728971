import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { take } from "rxjs/operators";

@Component({
  selector: "app-my-banners",
  templateUrl: "./my-banners.component.html",
  styleUrls: ["./my-banners.component.css"],
})
export class MyBannersComponent implements OnInit {
  instructor;
  sponsors = [];

  constructor(private router: Router, private firestore: AngularFirestore) {}

  expiryCodeDocId;

  ngOnInit(): void {
    console.log("going to call sponsors ");
    
    this.firestore
      .collection("sponsors")
      .get()
      .subscribe((data) => {
        console.log("datadocs : ",data);
        for (let item of data.docs) {
          console.log("item : ",item);
          console.log("item.data: ",item.data());
          let obj={};
          obj=item.data();
          this.sponsors.push({
            ...obj,
            id: item.id,
          });
        }
        console.log("posnors in component: ",this.sponsors);
        this.appendAuctions();
      });
  }

  async appendAuctions() {
    for (let item of this.sponsors) {
      item.auctions = await this.getAuctions(item.id);
    }
    return this.sponsors;
  }

  expireCodeCheck(docId) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode", (ref) =>
          ref.where("expiry_code", "==", docId)
        )
        .valueChanges()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length == 0) {
            this.router.navigateByUrl("/complete");
          } else {
            this.expiryCodeDocId = data[0];
            if (this.expiryCodeDocId.expired == true) {
              this.router.navigateByUrl("/expired");
            }
          }
        });
    });
  }

  getImageUrl(item: any) {
    if (item.auctions && item.auctions.length) {
      item.auctions.sort((a, b) => {
        return a.time.seconds - b.time.seconds;
      });
      return item.auctions[0].imageUrl;
    } else {
      return item.imageUrl;
    }
  }

  async getAuctions(bannerId: string) {
    if (!bannerId) {
      return [];
    }
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .collection(`auctions`, (ref) =>
            ref
              .where("bannerId", "==", bannerId)
              .where("isPaid", "==", true)
              .where("isClicked", "==", false)
          )
          .get()
          .subscribe((data) => {
            if (data) {
              const array = [];
              for (let item of data.docs) {
                let obj={};
                obj=item.data();
                array.push({
                  ...obj,
                  id: item.id,
                });
              }
              resolve(array.filter((f) => f.imageUrl));
            }
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
