import { Component, OnInit } from "@angular/core";
import axios from "axios";
// import * as firebase from "firebase";
import { Router } from "@angular/router";
import * as CONSTANTS from "../../constants";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { take } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';

interface Country {
  id?: number;
  name: string;
  flag: string;
  area: number;
  population: number;
}

@Component({
  selector: "app-instructor-list",
  templateUrl: "./instructor-list.component.html",
  styleUrls: ["./instructor-list.component.css"],
})
export class InstructorListComponent implements OnInit {
  users = [];
  user;
  constructor(private router: Router, private firestore: AngularFirestore, private toastr: ToastrService) {}

  ngOnInit() {
    axios({
      method: "get",
      url: `https://us-central1-wellness-winners.cloudfunctions.net/getAllUsers`,
    }).then(
      async (response) => {
        if (response.status === 200) {
          await Promise.all(
            response.data.map(async (user) => {
              user.magicLinksCounts = await this.getExpiryCodes(user.uid);
              user.role = await this.getUserRole(user.email);
              console.log("User Count", user);
              return user;
            })
          );
          this.users = response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  route(uid: any) {
    this.router.navigateByUrl("/sponsorsAdmin" + "/" + uid);
  }

  async getExpiryCodes(uid) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode", (ref) => ref.where("uid", "==", uid))
        .valueChanges()
        .subscribe((data) => {
          resolve(data.length);
        });
    });
  }

  async getUserRole(email) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("User", (ref) => ref.where("email", "==", email))
        .valueChanges()
        .subscribe((data:any) => {
          resolve(data.length ? data[0].role :"");
        });
    });
  }

  NavigateAddUser() {
    this.router.navigate(["/addUser"]);
  }

  deleteUser(uid) {
    axios({
      method: "get",
      url: `https://us-central1-wellness-winners.cloudfunctions.net/deleteUserById?uid=${uid}`,
    }).then(
      (response) => {
        if (response.status === 200) {
          this.users = this.users.filter((user) => {
            return user.uid !== uid;
          });
          // alert("Instructor deleted successfully");
        this.toastr.success('Instructor deleted successfully');

        }
      },
      (error) => {
        // alert("An error occurred");
        this.toastr.error('An error occurred');
        console.log(error);
      }
    );
  }

  async generateLink(uid) {
    let magicLink;
    const randomString = Math.random().toString(36).substr(2, 10);
    this.user = JSON.parse(localStorage.getItem("userData"));
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    await this.createExpiryCode({
      expiry_code: randomString,
      expired: false,
      uid,
    });
    magicLink = baseUrl + "/intro/" + uid + "/" + randomString;
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = magicLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    alert(magicLink);

    document.body.removeChild(selBox);
  }
  async createExpiryCode(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode")
        .add(data)
        .then(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
}
