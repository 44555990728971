<!-- <app-admin-layout *ngIf="!showToggle"></app-admin-layout> -->

<!-- For Desktop Layout -->

<div class="container-main mb-4 mx-auto">
  <div class="section-img">
    <img src="../../../assets/Bidding/bidding_bg.jpg" />
    <span>BID NOW !</span>
  </div>

  <!-- Active -->
  <div class="active-bg">
    <div class="row mt-4 mb-3">
      <div class="col-lg-12">
        <div
          _ngcontent-qmk-c112=""
          class="btn-price mt-1"
         >
          LIVE BIDS
      </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-md-6"
        id="higth"
        *ngFor="let auction of getActiveAuctions(); index as i"
      >
        <div class="img-main">
    <!-- <img src="../../../assets/Bidding/AA-Bidding_ARC-files-02.png" /> -->
          
            <h3>BANNER AD 1</h3>
            <div id="price">
            <label htmlFor=" auction.bannerId"></label>
            <label>Price: {{ auction.price }}</label>
          </div>
          <div id="price">
          <label>Sponser Id: {{ auction.bannerId }}</label>
        </div>
        <div id="price">
          <label>Instructor Id: {{ auction.instructorId }}</label>
        </div>
        <!-- <div id="price">
          <label htmlFor=" auction.bannerId"></label>
          <label>Time: {{ toDateString(auction.time.seconds * 1000) }}</label>
        </div> -->

        <div id="price">
          <label htmlFor=" auction.bannerId"></label>
          <label>Date: {{ auction.date }}</label>
        </div>
        <div id="price">
          <label htmlFor=" auction.bannerId"></label>
          <label>Time: {{ auction.onlyTime }}</label>
        </div>

        <div id="btn-main">
            <button
              class="btn-price"
              (click)="goToAuctionPage(auction.id, 0)"
            >
              More details
            </button>
          </div>
       
        </div>
      </div>
    </div>
  </div>

  <!-- Expired -->
  <div class="expire-bg">
    <div class="row mt-3 mb-3">
      <div class="col-lg-12">
        <div
         class="btn-price"
        >
          Expired
      </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let auction of expiredAuctions; index as i">
        <div  class="img-main2">
          <h3>BANNER AD 4</h3>
          <div id="price">
            <label htmlFor=" auction.bannerId"></label>
            <label>Price: {{ auction.price }}</label>
          </div>
          <div id="price">
          <label>Sponser Id: {{ auction.bannerId }}</label>
        </div>
        <div id="price">
          <label>Instructor Id: {{ auction.instructorId }}</label>
        </div>
        <div id="price">
          <label htmlFor=" auction.bannerId"></label>
          <label>Date: {{ auction.date }}</label>
        </div>
        <div id="price">
          <label htmlFor=" auction.bannerId"></label>
          <label>Time: {{ auction.onlyTime }}</label>
        </div>
          <div id="btn-main">
            <button
              class="btn-price"
              (click)="goToAuctionPage(auction.id, 0)"
            >
              More details
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
