import { ToastrService } from 'ngx-toastr';
// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import * as CONSTANTS from "../../../constants";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
// import * as firebase from "firebase";
import { AuthService } from "../../../auth.service";
import axios from "axios";
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "sponsor-image",
  templateUrl: "./sponsor-image.component.html",
  styleUrls: ["./sponsor-image.component.css"],
})
export class SponsorImageComponent implements OnInit {
  @Input() score = "";
  @Input() total = "";
  @Input() imageUrl = "";
  @Input() sponsorId = null;
  wallet;
  user;
  todo: boolean = false;
  doto: boolean = false;
  instructorId = null;
  auction: Auction[];
  price: "125";
  time: any;
  magicLink = "";
  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private auctionService: AuctionService,
    public auth: AuthService, 
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.instructorId = this.route.snapshot.params.instructorId;

    this.user = JSON.parse(localStorage.getItem("userData"));
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    this.auth.user.subscribe((res) => {
      try {
        const user: any = res.toJSON();
        if (user) {
          axios
            .get(`https://api.coinbase.com/v2/accounts`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "AA_COINBASE_ACCESS_TOKEN"
                )}`,
              },
            })
            .then((response) => {
              console.log("response fetched");
              this.wallet = response.data.data[0];
              console.log(this.wallet);
            })
            .catch((error) => {
              console.log("error in fetching");
              console.log(error);
            });
        }
      } catch (ex) {
        console.log(ex);
      }
    });
  }
  copyMagicLink2() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.magicLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.magicLink = "";
  }

  async generateLink2() {
    this.doto = true;
    const randomString = Math.random().toString(36).substr(2, 10);
    console.log("randomString", randomString);
    this.user = JSON.parse(localStorage.getItem("userData"));
    const uid = this.user?.uid;

    await this.createExpiryCode({
      expiry_code: randomString,
      expired: false,
      uid,
    });
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.magicLink = baseUrl + "/auction-list/";
  }
  async createExpiryCode(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode")
        .add(data)
        .then(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  async create() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const res = await this.auctionService.createAuction({
      price: 1,
      isClicked: false,
      bannerId: this.sponsorId,
      instructorId: user?.uid,
      time: this.getTomorrow(),
    });
    if (res && res.id) {
      // alert("Auction created successfully");
      this.toastr.success('Auction created successfully');
      this.todo = true;
    }
    return true;
  }

  getTomorrow() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }
}
