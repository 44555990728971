import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { Subject } from "rxjs";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import {
  uDonate_abi,
  uDonate_address,
  NFT_Address,
  NFT_Abi,
  Market_Adress,
  market_Abi,
} from "src/abis";
import { ethers } from "ethers";
const ipfsClient = require("ipfs-http-client");

// Infura  Client **************************
const projectId = "2EoLB6MSQ9g1Dfn1cVVhNGvxrWK";
const projectSecret = "75aab0bdfb80083ca2ffb3e74211ccc0";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
const client = ipfsClient.create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

@Injectable({
  providedIn: "root",
})
export class MetaMaskWalletService {
  private web3js: any;
  private provider: any = undefined;
  private accounts = [];
  web3Modal;
  uDonate: any;

  private accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  constructor(private http: HttpClient) {
    const providerOptions = {
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Web 3 Modal",
          infuraId: process.env.INFURA_KEY,
        },
      },
      // walletconnect: {
      //   package: WalletConnectProvider, // required
      //   options: {
      //     infuraId: "INFURA_ID", // required
      //   },
      // },
    };

    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)",
      },
    });
  }

  async connectAccount() {
    this.web3Modal.clearCachedProvider();
    this.provider = await this.web3Modal
      .connect()
      .catch((err) => console.log("error occured in creating")); // set provider
    this.web3js = new Web3(this.provider); // create web3 instance
    this.accounts = await this.web3js.eth.getAccounts();
    // this.accountStatusSource.next(this.accounts);
    return this.accounts[0];
  }

  async getWalletBalance(walletAddress: string) {
    this.web3Modal.clearCachedProvider();
    this.provider = await this.web3Modal.connect().catch((err) => {
      console.log("error occured in creating");
      return;
    }); // set provider
    this.web3js = new Web3(this.provider); // create web3 instance
    let balance: number = await this.web3js.eth.getBalance(walletAddress);
    return balance;
  }

  async donate(walletAddress: string, amount: number, mesage?: string) {
    this.provider = await this.web3Modal.connect(); // set provider
    this.web3js = new Web3(this.provider); // create web3 instance
    this.accounts = await this.web3js.eth.getAccounts();

    this.uDonate = new this.web3js.eth.Contract(uDonate_abi, uDonate_address);
    // console.log("what is uDonate Contract :", this.uDonate);
    const updatedAmt = amount * 1e18;
    const donate = await this.uDonate.methods
      .donateETH(this.accounts[0])
      .send({ from: walletAddress, value: updatedAmt });
    return donate;
  }

  async clearCachedProviderAndLogout() {
    await this.web3Modal.clearCachedProvider();
  }

  GetETHExchangeRate() {
    let url: string = "https://api.coinbase.com/v2/exchange-rates?currency=USD";
    return this.http.get<any>(url).toPromise();
  }

  // Etherium

  nft: any;
  market: any;

  async InfuraImagePath(file) {
    console.log("getting image path with infura");
    const result = await client.add(file);
    console.log(result);
    const imagePath = `https://ipfs.infura.io/ipfs/${result.path}`;
    console.log("image path", imagePath);
    return imagePath;
  }

  // async Contracts() {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const signer = provider.getSigner();
  //   console.log("Signer: ", signer);
  //   //this.nftContract = new ethers.Contract(uDonate_address, NFT_ABI, signer);
  //   this.nft = new ethers.Contract(NFT_Address, NFT_Abi, signer);
  //   console.log("NFt Contract : ", this.nft);
  //   // this.market = new ethers.Contract(NFTMarketAddress, Martket_ABI, signer);
  // }

  async MintAndListNFT(NFTData, price) {
    const result = await client.add(NFTData);
    const uri = `https://ipfs.infura.io/ipfs/${result.path}`;
    // getting Signer
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    console.log("Signer: ", signer);
    // NFT Contract
    this.nft = new ethers.Contract(NFT_Address, NFT_Abi, signer);
    let mint = await this.nft.mint(uri);
    console.log("Minted: ", mint);
    const id = await this.nft.tokenCount();
    console.log("Id", id);
    // await nft.setApprovalForAll(, true)
    await this.nft.setApprovalForAll(Market_Adress, true);
    // listing NFT
    const marketplace = new ethers.Contract(Market_Adress, market_Abi, signer);
    const listingPrice = ethers.utils.parseEther(price.toString());
    console.log("marketplace", marketplace);
    let ItemListed = await (
      await marketplace.makeItem(this.nft.address, id, listingPrice)
    ).wait();
    return ItemListed;
  }

  async getList() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    this.nft = new ethers.Contract(NFT_Address, NFT_Abi, signer);
    const marketplace = new ethers.Contract(Market_Adress, market_Abi, signer);
    const itemCount = await marketplace.itemCount();
    this.accounts = await this.connectAccount();
    let TotalNFTSOwnedByAdmin = [];
    for (let indx = 1; indx <= itemCount; indx++) {
      const i = await marketplace.items(indx);
      let uri = await this.nft.tokenURI(i.tokenId);
      let righturi = uri.replace("ipfs.infura.io", "ipfs.io");
      const resp = await this.getDataWithHTTPClient(righturi).catch((err) =>
        console.log(err)
      );
      const totalPrice = await marketplace.getTotalPrice(i.itemId);
      if (i.sold == false) {
        let item = {
          tokenId: i.tokenId,
          totalPrice,
          price: i.price,
          itemId: i.itemId,
          name: resp?.name,
          description: resp?.description,
          image: resp?.image,
        };
        TotalNFTSOwnedByAdmin.push(item);
      }
    }
    console.log("outisde the loop");
    return TotalNFTSOwnedByAdmin;
  }

  getDataWithHTTPClient(URL) {
    return this.http.get<any>(URL).toPromise();
  }

  // async purchaseItem(item) {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const signer = provider.getSigner();
  //   // this.nft = new ethers.Contract(NFT_Address, NFT_Abi, signer);
  //   const marketplace = new ethers.Contract(Market_Adress, market_Abi, signer);
  //   console.log("item.totalPrice", item.totalPrice);
  //   await (
  //     await marketplace.purchaseItem(item.itemId, { value: item.totalPrice })
  //   ).wait();
  // }

  async NFTTransferToVisitor(item) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const marketplace = new ethers.Contract(Market_Adress, market_Abi, signer);
    let NFTTrasnfer = await marketplace.giftNFT(item.itemId);
    return NFTTrasnfer;
  }

  async getDataByTokenId(tokenId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const nft = new ethers.Contract(NFT_Address, NFT_Abi, signer);
    let uri = await this.nft.tokenURI(tokenId);
    console.log("uri to get data : ", uri);
    let righturi = uri.replace("ipfs.infura.io", "ipfs.io");
    console.log("right url : ", righturi);
    const resp = await this.getDataWithHTTPClient(righturi).catch((err) =>
      console.log(err)
    );
    console.log("data got by tokenID: ", resp);
    return resp;
  }
}
