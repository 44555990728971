import { ToastrService } from "ngx-toastr";
import { MetaMaskWalletService } from "src/app/services/meta-mask-wallet.service";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-metamask-app",
  templateUrl: "./metamask-app.component.html",
  styleUrls: ["./metamask-app.component.css"],
})
export class MetamaskAppComponent implements OnInit {
  MyNFTS = [];
  loadingNFT: boolean = false;
  constructor(
    private NFTSerive: MetaMaskWalletService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllNFTS();
  }

  async getAllNFTS() {
    try {
      this.loadingNFT = true;
      this.MyNFTS = await this.NFTSerive.getList();
      console.log("All NFts ,", this.MyNFTS);
      this.getNFTImagesPath();
      this.loadingNFT = false;
    } catch (error) {
      this.loadingNFT = false;
    }
  }

  getNFTImagesPath() {
    for (let i = 0; i < this.MyNFTS?.length; i++) {
      if (this.MyNFTS[i].image)
        this.MyNFTS[i]["image"] = this.MyNFTS[i].image.replace(
          "ipfs.infura.io",
          "ipfs.io"
        );
    }
    console.log("my nfts data with right image path : ", this.MyNFTS);
  }

  async GiftNFT(item) {
    let isPaymentFaild = false;
    let Trasnfered = await this.NFTSerive.NFTTransferToVisitor(item).catch(
      (err) => {
        isPaymentFaild = true;
        this.toastr.error("Payment faild " + err.message);
      }
    );
    if (!isPaymentFaild) {
      this.toastr.success("Transfered succesfully");
      await this.getAllNFTS();
    }
    console.log("trasnfered: ", Trasnfered);
  }
}
