import { MetaMaskWalletService } from "src/app/services/meta-mask-wallet.service";
import { Component, OnInit } from "@angular/core";
import { BidRecordService } from "src/app/bidRecord.service";
import { BidRecord } from "src/app/bidRecord.model";
import { finalize } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
import { isNgTemplate } from "@angular/compiler";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-bid-profile",
  templateUrl: "./bid-profile.component.html",
  styleUrls: ["./bid-profile.component.css"],
})
export class BidProfileComponent implements OnInit {
  constructor(
    private bidRecordService: BidRecordService,
    private af: AngularFireStorage,
    private auctionService: AuctionService,
    private toastr: ToastrService,
    private metaMaskService: MetaMaskWalletService
  ) {
    // debugger;
  }
  BidHistory: any[];
  Auction: any[];
  public imagePath;
  imgURL: any;
  imageShow: any;
  public message: string;
  sponsorId: any;
  isUploading = false;
  UpdateName = JSON.parse(localStorage.getItem("userData"));
  UpdateNamedata = this.UpdateName.userName;
  loginUser = this.UpdateName ? this.UpdateName.uid : null;
  sponsor: any;
  IdImage: any;
  ImagePreview: any;
  path: String;
  auctionId: any;
  reward: any = { code: "asdf", percentage: 10 };
  bidHistoryPreview: any;
  height: any;
  width: any;

  info() {
    const userId = JSON.parse(localStorage.getItem("userData"));
    document.getElementById("email").innerHTML = userId.email;
    return true;
  }

  isBidApproved(bidId, sponsor) {
    if (!sponsor) {
      return false;
    }
    return sponsor.auctions.find((f) => f.isPaid && f.bidId === bidId);
  }

  ngOnInit() {
    // debugger;
    this.bidRecordService
      .getBidHistoryBySpecificUser(this.loginUser)
      .subscribe(async (data) => {
        this.BidHistory = data.map((e) => {
          return {
            ...(e.payload.doc.data() as {}),
            id: e.payload.doc.id,
          } as BidRecord;
        });
        // let tempData = [];
        // for (let item of this.BidHistory) {
        //   const sponsor = await this.bidRecordService.getSponsor(item.bannerId);

        //   tempData.push({ ...item, sponsor });
        //   this.auctionId = item.auctionId;
        // }

        // this.BidHistory = tempData;
        this.getAuctionOfUser();
        console.log("bidHistory", this.BidHistory);
      });

    // this.auctionService
    //   .getAuctionByUser(this.loginUser)
    //   .subscribe(async (data) => {
    //     this.Auction = data.map((e) => {
    //       return {
    //         id: e.payload.doc.id,
    //         ...(e.payload.doc.data() as {}),
    //       } as Auction;
    //     });

    //     let tempData = [];
    //     for (let item of this.Auction) {
    //       const sponsor = await this.auctionService.getSponsor(item.bannerId);

    //       tempData.push({ ...item, sponsor });
    //     }

    //     this.Auction = tempData;
    //   });
  }

  async getAuctionOfUser() {
    for (let item of this.BidHistory) {
      item.auction = await this.auctionService.getAuctionById(item.auctionId);
    }
  }
  upload($event, files: any) {
    console.log("image", $event.target.files[0]);
    console.log("image size", $event.target.files[0]);
    this.path = $event.target.files[0];
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      //Read the contents of Image File.

      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = this.imgURL;

      //Validate the File Height and Width.
      image.onload = () => {
        // var height = this.height;
        var width = image.width;
        if (width < 315) {
          this.toastr.error("Width must be greater to  300");

          return false;
        }
        this.toastr.success("Uploaded image has valid Width.");
        this.imageShow = image.src;
        this.ImageFile = $event.target.files[0];
        return true;
      };
    };
  }

  async uploadImage(bidHistory: any) {
    const imageId = bidHistory.auction.id;
    console.log("reward", this.reward);
    if (!this.path) {
      return false;
    }
    this.isUploading = true;
    const filePath = `SponsorsImages/${new Date().valueOf()}`;
    const task = this.af.upload(filePath, this.path);
    const fileRef = this.af.ref(filePath);
    let downloadURL;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          downloadURL = fileRef.getDownloadURL();
          downloadURL.subscribe(async (url: any) => {
            // let auctions: any;
            // auctions = this.Auction;
            // auctions.imageUrl = url;
            await this.auctionService.updateImage(
              imageId,
              url,
              this.reward.code,
              this.reward.percentage,
              bidHistory.quantity
            );
            this.isUploading = false;
            // // alert("Picture uploaded successfully");
            this.toastr.success("Picture uploaded successfully");
          });
        })
      )
      .subscribe((url) => {
        if (url) {
        }
      });
  }

  async getSponsor(id: string, auctionId: string, bidHistoryData: any) {
    const res = await this.bidRecordService.getSponsor(id);
    this.IdImage = id;
    this.auctionId = auctionId;

    // this.ImagePreview = new File(["image"], this.sponsor.auctions[0].image);
    // alert(this.ImagePreview);
    // const read = new FileReader();
    // read.readAsDataURL(this.sponsor.auctions[0].image);
    // read.onload = (_event) => {
    //   this.ImagePreview = read.result;
    // };
    this.bidHistoryPreview = bidHistoryData;
    console.log("shownData", this.bidHistoryPreview);

    if (res) {
      this.sponsor = res;
      if (this.bidHistoryPreview.auction.imageUrl) {
        this.ImagePreview = this.bidHistoryPreview.auction.imageUrl;
      }

      if (!this.bidHistoryPreview.auction.imageUrl) {
        this.ImagePreview = this.sponsor.imageUrl;
      }
      if (this.bidHistoryPreview.auction.rewardCode) {
        this.reward = {
          code: this.bidHistoryPreview.auction.rewardCode,
          percentage: this.bidHistoryPreview.auction.rewardPercentage,
        };
      }

      if (!this.bidHistoryPreview.auction.rewardCode) {
        this.reward = {
          code: this.sponsor.rewardCode,
          percentage: this.sponsor.rewardPercentage,
        };
      }

      // this.auctionTime = this.sponsor.auctions[0].time;
      // this.price = this.sponsor.auctions[0].price;
      // if (this.auctionTime) {
      //   this.handleTime();
      //   const that = this;
      //   this.timeId = setInterval(function () {
      //     that.handleTime();
      //   }, 1000);
      // }
    }
    return res;
  }
  toDateString(val: any) {
    if (!val) {
      return null;
    }
    return new Date(val).toLocaleString();
  }

  // For NFT
  ImageFile: any;
  isLoading: boolean = false;
  async CreateAndLitNFt() {
    try {
      this.isLoading = true;
      console.log("image file : ", this.ImageFile);
      if (this.ImageFile == undefined || this.ImageFile == null) {
        this.toastr.warning("Please Upload An image");
        return;
      }
      let imageURL = await this.metaMaskService
        .InfuraImagePath(this.ImageFile)
        .catch((err) => {
          console.log("error in : ", err);
          this.isLoading=false;
          return;
        });
      console.log("image URL", imageURL);
      if (imageURL == undefined || imageURL == null) {
        this.toastr.error("image saving error");
        this.isLoading = false;
        return;
      }
      let listed = await this.NFT(imageURL);
      this.isLoading = false;
      this.toastr.success(
        "Minted and listed Successfully, trasnaction hash: ",
        listed?.transactionHash
      );
    } catch (error) {
      this.toastr.warning("Error occured in Minting: " + error);
      this.isLoading = false;
    }
  }

  async NFT(imageURL) {
    let objForNft = {
      image: imageURL,
      price: this.reward.percentage,
      name: this.reward.code,
      description: this.reward.code,
    };
    let NFTData = JSON.stringify(objForNft);
    console.log("NFT Data", NFTData);
    let mintedAndListed = await this.metaMaskService.MintAndListNFT(
      NFTData,
      objForNft.price
    );
    console.log("minted and listed: ", mintedAndListed);
    return mintedAndListed;
  }
}
