<div *ngIf="auth.user | async as user; else login">
  <h1>{{user.toString()}}</h1>
<h3>UID: {{user.uid}}</h3>
<!--  <button (click)="auth.login()">Login</button>-->
</div>


<h3>{{wallet.currency.code}}</h3>
<h3>{{wallet.balance.amount}}</h3>

<button (click)="auth.login()">Login</button>
<ng-template #login>
  <button (click)="auth.login()">Login</button>
</ng-template>
