import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BidRecord } from "src/app/bidRecord.model";

@Injectable({
  providedIn: "root",
})
export class BidRecordService {
  constructor(private firestore: AngularFirestore) {}

  createBidHistory(bidRecord: BidRecord) {
    return this.firestore.collection("bidHistory").add(bidRecord);
  }
  getBidHistory() {
    return this.firestore
      .collection("bidHistory", (ref) => ref.orderBy("bidAmount", "desc"))
      .snapshotChanges(); 
      
  }

  getBidHistoryByUserAndAuction(uid: string, auctId) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
      .collection("bidHistory", (ref) =>
        ref.where("userId", "==", uid).where("auctionId", "==", auctId)
      )
        // .select()
        .get()
        .subscribe((doc: any) => {
          resolve(
            doc.docs.map((snap: any) => {
              return { ...snap.data(), id: snap.id };
            })
          );
        });
    });
  }

  getBidHistoryByUser(uid: string, auctId) {
    return this.firestore
      .collection("bidHistory", (ref) =>
        ref.where("userId", "==", uid).where("auctionId", "==", auctId)
      )
      .snapshotChanges();
  }
  getBidHistoryBySpecificUser(uid: string) {
    return this.firestore
      .collection("bidHistory", (ref) => ref.where("userId", "==", uid))
      .snapshotChanges();
  }
  async getSponsor(sponsorId: string) {
    return new Promise((resolve, reject) => {
      try {
        this.firestore
          .doc(`sponsors/${sponsorId}`)
          .get()
          .subscribe((data) => {
            if (data) {
              let obj={}
              obj=data.data();
              resolve({ ...obj, id: data.id });
            }
            resolve(null);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async updateBidHistory(bidRecord: BidRecord) {
    const bidRecordId = bidRecord.id;
    delete bidRecord.id;
    await this.firestore.doc("bidHistory/" + bidRecordId).update(bidRecord);
  }
  async updateImage(sponsorId, auctions) {
    await this.firestore.doc("sponsors/" + sponsorId).update({ auctions });
  }
}
