import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
// import * as firebase from 'firebase/app';
import {HttpClient} from '@angular/common/http';
import axios from 'axios'


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  providers: [AuthService]

})
export class UserDetailsComponent implements OnInit {
  wallet;
  login: any;

  constructor(public auth: AuthService, private http: HttpClient) {
  }

  ngOnInit() {
    this.auth.user.subscribe(res => {
      try {
        const user:any = res.toJSON();
        const date = new Date()
        if (user) {
          axios.get(`https://api.coinbase.com/v2/accounts`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("AA_COINBASE_ACCESS_TOKEN")}`
              }
            }
          ).then(response => {
            console.log("response fetched")
            this.wallet = response.data.data[0]
            console.log(this.wallet)
          }).catch(error => {
            console.log("error in fetching")
            console.log(error)
          })
        }
  } catch
    (ex)
    {
      console.log(ex)
    }
  });
  }

  getWallet() {
    console.log(this.auth.user);
  }

}
