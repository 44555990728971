import {Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import axios from 'axios';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  rewardCode;
  rewardPercentage;
  @ViewChild('userEmail', {static: true}) userEmail: ElementRef;
  constructor(private router: Router, private route: ActivatedRoute, private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.rewardCode = this.route.snapshot.params.rewardCode;
    this.rewardPercentage = this.route.snapshot.params.rewardPercentage;
    console.log(this.route.snapshot.params);

  }

  OnInput(event: any) {
    this.userEmail = event.target.value;
  }

  handleSubmit() {
    const mail = this.userEmail.nativeElement.value;
    if (!this.validateEmail(mail)) {
      alert('Invalid email address');
      return false;
    }
    if (this.rewardCode && this.rewardPercentage) {
      axios({
        method: 'get',
        url: `https://us-central1-active-alligator.cloudfunctions.net/helloWorld?email=${mail}&percentage=${this.rewardPercentage}&code=${this.rewardCode}`,
      }).then((response) => {
          console.log(response);
      }, (error) => {
          console.log(error);
      });
    } else {
      console.log('Sponsor falied');
    }
    const feedbackID = localStorage.getItem('FeedbackID');
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('feedback')
        .doc(feedbackID)
        .update({email: mail})
        .then(res => {
          const uidValue= localStorage.getItem('uid');
          const expiryCode = localStorage.getItem("expiryCode")
          this.router.navigateByUrl("/complete")
        }, err => reject(err));
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
