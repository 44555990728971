<div class="container-fluid">
	<div class="row">
		<div class="col-md-4 mx-auto py-4 wrapper" style="text-align: center;">
			<img class="my-3" src="assets/images/result/sorry.png" />
			<div class="clearfix"></div>
			<div class="divMarkup">
			<img src="assets/images/result/icon_sorry.png" />
			</div>
			<div class="clearfix"></div>
			<a (click)="handleLostClick()"><img class="mt-2 mb-1" src="assets/images/result/next_time.png" /></a>
		</div>
	</div>
</div>
