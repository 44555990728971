import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
// import * as firebase from "firebase";
import { Router } from "@angular/router";
import axios from "axios";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UserService } from "src/app/user.service";
import { ToastrService } from "ngx-toastr";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  SignUpForm: any;
  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private userService: UserService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.SignUpForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      wallet: [null, [Validators.required]],
      date: [formatDate(new Date(), "yyyy-MM-dd", "en"), Validators.required],
      userName: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  get form() {
    return this.SignUpForm.controls;
  }

  async handleSubmit() {
    if (this.SignUpForm.valid == false) {
      this.SignUpForm.markAllAsTouched();
      return;
    }

    const email = this.SignUpForm.value.email;
    const wallet = this.SignUpForm.value.wallet;
    const date = this.SignUpForm.value.date;
    const userName = this.SignUpForm.value.userName;

    await this.userService
      .createUser({
        userName: userName,
        email: email,
        date: date,
        wallet: wallet,
        role: "Instructor",
      })
      .catch((err) => this.toastr.error("error occured"))
      .finally(() => {
        console.log("Finaly created : ");
        this.toastr.success("Instructor create sucessfully");
        this.router.navigateByUrl("/list-instructors");
      });

    // try {
    //   const result = await axios({
    //     method: "get",
    //     url: `https://us-central1-wellness-winners.cloudfunctions.net/createUser?email=${email}&password=${wallet}`,
    //   });
    //   if (result && result.status === 200) {
    //     this.firestore
    //       .collection("sponsors")
    //       .get()
    //       .subscribe((data) => {
    //         const feedbacks = data.docs;
    //         feedbacks.map((feedback) => {
    //           let scores = feedback.data()["instructorScores"];
    //           scores = [...scores, { uid: result.data, score: 10 }];
    //           return new Promise<any>((resolve, reject) => {
    //             this.firestore
    //               .collection("sponsors")
    //               .doc(`${feedback.id}`)
    //               .update({ instructorScores: scores })
    //               .then(
    //                 (res) => {
    //                   console.log("updated");
    //                   resolve('update');
    //                 },
    //                 (err) => reject(err)
    //               );
    //           });
    //         });
    //       });
    //     // this.userService.createUser({
    //     //   userName: userName,
    //     //   email: email,
    //     //   date: date,
    //     //   wallet: wallet,
    //     //   role: "Instructor",
    //     // });
    //     // this.toastr.success('Instructor create sucessfully');
    //     // this.router.navigateByUrl("/list-instructors");
    //   }
    // } catch (ex) {
    //   this.toastr.error('Server not responding');
    //   console.log(ex);
    // }
  }
}
