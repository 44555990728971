import { Component, OnInit } from "@angular/core";
import { FirebaseService } from "./services/firebase.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import axios from "axios";
// import * as firebase from "firebase";
import * as CONSTANTS from "./constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  rating = [
    {
      label: "awesome",
      logo: "assets/images/rating/awesome.png",
    },
    {
      label: "active",
      logo: "assets/images/rating/active.png",
    },
    {
      label: "average",
      logo: "assets/images/rating/average.png",
    },
  ];
  loggedIn = false;
  selectedRating = "";
  title = "active-alligator";
  showSignup = false;
  showLogin = false;
  showAdminPenal = false;
  showForgotPassword = false;
  showProfile = false;
  showInstructorList = false;
  showRating = false;
  showRated = false;
  showSponsor = false;
  showWonComp = false;
  showSorryComp = false;
  showSubscriptionComp = false;
  sponsors = [];
  sponsor = {};
  theuser = {};

  appSponsors = [
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad1.png?alt=media&token=f0681c85-016e-478e-84b1-40cd38ae9230",
      name: "Sponsor1",
      rewardCode: "P4OjrrPkI8",
      rewardPercentage: 10,
      score: 7,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad2.png?alt=media&token=9c63fcab-19dd-47bf-bb53-dd47c23ecf34",
      name: "Sponsor2",
      rewardCode: "WYehgGq1cs",
      rewardPercentage: 15,
      score: 10,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad3.png?alt=media&token=ff35409b-b0cc-4ea2-8776-74be676785e2",
      name: "Sponsor3",
      rewardCode: "iGxsffCX1f",
      rewardPercentage: 20,
      score: 10,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad4.png?alt=media&token=ed97af8f-9f83-45d7-bcc7-1bf6c04e47f1",
      name: "Sponsor4",
      rewardCode: "P6c580ptvC",
      rewardPercentage: 20,
      score: 10,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad5.png?alt=media&token=7e314f43-9cae-4047-b4a7-de9dbd40453d",
      name: "Sponsor5",
      rewardCode: "bHMzlMA4du",
      rewardPercentage: 25,
      score: 9,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/active-alligator.appspot.com/o/ad6.png?alt=media&token=aa656367-feb1-4b8b-852b-ffb5a55efd56",
      name: "Sponsor6",
      rewardCode: "kB3pb6SUVg",
      rewardPercentage: 30,
      score: 7,
    },
  ];

  constructor(
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {}

  onRatingSelect(label) {
    this.selectedRating = label;
    this.showRating = false;
    this.showRated = true;
    this.createFeedback({ rating: label });
  }

  showSponsors() {
    this.showRated = false;
    this.showSponsor = true;
    this.getList();
  }

  showResult(item) {
    this.sponsor = item;
    this.showSponsor = false;
    if (item.payload.doc.data().score > 0) {
      this.showWonComp = true;
    } else {
      this.showSorryComp = true;
    }
  }
  showSubscription() {
    this.showSorryComp = false;
    this.showSubscriptionComp = true;
  }

  wonClick(sponsor) {
    this.sponsor = sponsor;
    this.showWonComp = false;
    this.showSubscriptionComp = true;
  }

  // profileClick = (data) => {
  //   var user = firebase.auth().currentUser;
  //   user
  //     .updateProfile(data)
  //     .then(function () {})
  //     .catch(function (error) {
  //       alert("An error happened.");
  //     });
  // };

  // signupClick(data) {
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(data.email, data.pass)
  //     .then(function (user) {
  //       console.log("user", user);
  //       console.log("user is created");
  //     })
  //     .catch(function (error) {
  //       // Handle Errors here.
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       console.log("error", error);
  //       // ...
  //     });
  // }
  subscriptionClick(sponsor) {
    this.showRating = true;
    let email = sponsor;
    if (sponsor.email != undefined) {
      email = sponsor.email;
    }
    if (sponsor.won) {
      console.log("Sponsor won");
      axios({
        method: "get",
        url: `https://us-central1-active-alligator.cloudfunctions.net/helloWorld?email=${email}&percentage=${
          sponsor.payload.doc.data().rewardPercentage
        }&code=${sponsor.payload.doc.data().rewardCode}`,
      }).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    } else console.log("Sponsor falied");
    let FeedbackID = localStorage.getItem("FeedbackID");
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("feedback")
        .doc(FeedbackID)
        .update({ email: email })
        .then(
          (res) => {
            this.showSubscriptionComp = false;
            this.showRating = true;
          },
          (err) => reject(err)
        );
    });
  }

  async ngOnInit() {
    // setTimeout(() => {
    //   this.showIntro = false;
    //   this.showSignup = true;
    // }, 2000);
    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     localStorage.setItem(CONSTANTS.USER, JSON.stringify(user));
    //     this.loggedIn = true;
    //     console.log("user is logged in ", user);
    //     this.theuser = user;
    //     console.log("user is", this.theuser.uid);

    //     // User is signed in.
    //   } else {
    //     localStorage.removeItem(CONSTANTS.USER);
    //     // No user is signed in
    //     console.log("user is not logged in");
    //   }
    // });
  }

  selectedSponsor(item) {
    this.showResult(item);
    let score = item.payload.doc.data().score;
    let doc_id = item.payload.doc.id;
    if (score > 0) {
      score -= 1;
    }
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("sponsors")
        .doc(`${doc_id}`)
        .update({ score: score })
        .then(
          (res) => {
            this.showResult(item);
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  getList() {
    this.firestore
      .collection("sponsors")
      .snapshotChanges()
      .subscribe((data) => {
        this.sponsors = data;
      });
  }

  createFeedback(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("feedback")
        .add(data)
        .then(
          (res) => {
            localStorage.setItem("FeedbackID", res.id);
          },
          (err) => reject(err)
        );
    });
  }
}
