<div class="container-fluid">
  <div class="row">
		<div class="col-md-4 mx-auto py-4 wrapper" style="text-align: center;">
      <img src="assets/subscription/lblSubscription.png" />
      <div class="clearfix"></div>
      <form>
        <div class="form-group my-5">
				<input placeholder="Your email address" #userEmail  required/>
        </div>
      </form>
      <div class="clearfix"></div>
			<div  (click)="handleSubmit()">
        <img class="mt-2 mb-1" src="assets/subscription/btnSubmit.png" />
      </div>
    </div>
  </div>
</div>
