<app-admin-layout></app-admin-layout>
<div class="container">
  <br />
  <div class="card">
    <h1>Auctions List</h1>
    <div style="overflow-x: auto">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Sponser Id</th>
            <!-- <th scope="col">Instructor Id</th> -->
            <th scope="col">Price</th>
            <th scope="col">Time</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody *ngFor="let auction of Auctions; index as i">
          <tr>
            <td>{{ auction.bannerId }}</td>
            <td>
              <!-- {{ auction.instructorId }} -->
            </td>
            <td>{{ auction.price }}</td>
            <td>{{ toDateString(auction.time.seconds * 1000) }}</td>
            <td>
              <button
                class="btn-price mt-1"
                (click)="goToAuctionPage(auction.id)"
              >
                Auction's Page
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <br />
</div>
