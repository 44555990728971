import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
// import * as firebase from "firebase";
import * as CONSTANTS from "../../constants";
import { environment } from "src/environments/environment";
import { User } from "src/app/user.model";
import { UserService } from "src/app/user.service";
import { ToastrService } from "ngx-toastr";
import { MetaMaskWalletService } from "src/app/services/meta-mask-wallet.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.css"],
})
export class AdminLayoutComponent implements OnInit {
  showSidebar = false;
  userdata: User[];
  user = {};
  activeTabId = "security";
  isAdmin = false;
  isBidder = false;
  isInstructor = false;
  AccountBalance: number;
  isLoading: boolean = false;
  uid = null;
  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private walletConnectService: MetaMaskWalletService
  ) {
    // const data = firebase.auth().currentUser;
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }
  auctionList() {
    this.showSidebar = !this.showSidebar;
    this.router.navigate([`/auction-list`]);
    console.log("testing ")
  }

  async ngOnInit() {
    this.isLoading = true;
    const storedUser = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    if (storedUser) {
      let account: string = storedUser.wallet;
      this.userdata = await this.getUserDataByWalletAddress(account);
      const userObj = this.userdata[0];
      if (this.userdata) {
        this.isLoading = false;
        if (userObj.role == "Bidder") {
          console.log("this is bidder");
          this.isBidder = true;
        } else if (userObj.role == "Admin") {
          console.log("this is Admin");
          this.isAdmin = true;
        } else if (userObj.role == "Instructor") {
          console.log("this is Instructor");
          this.isInstructor = true;
        } else {
          // alert("invalid");
        }
      }
    } else {
      console.log("user did'nt match with stored user");
      this.isLoading = false;
      this.isInstructor = false;
      this.isBidder = false;
      this.isAdmin = false;
    }
  }

  // Logout() {
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(
  //       () => {
  //         localStorage.removeItem("uid");
  //         this.router.navigate(["/"]);
  //       },
  //       (error) => {
  //         // alert(error);
  //         this.toastr.error("error");
  //       }
  //     );
  // }
  setActiveTab(tabId: string) {
    this.activeTabId = tabId;
  }

  async Logout() {
    localStorage.removeItem("uid");
    await this.walletConnectService
      .clearCachedProviderAndLogout()
      .catch((err) => console.log(err));
    localStorage.clear();
    this.isLoading = false;
    this.isBidder = false;
    this.isAdmin = false;
    this.isInstructor = false;
    console.log("logged out");
    this.router.navigate(["/"]);
    // firebase
    //   .auth()
    //   .signOut()
    //   .then(
    //     async () => {
    //       localStorage.removeItem("uid");
    //       await this.walletConnectService
    //         .clearCachedProviderAndLogout()
    //         .catch((err) => console.log(err));
    //       localStorage.clear();
    //       this.isLoading = false;
    //       this.isBidder = false;
    //       this.isAdmin = false;
    //       this.isInstructor = false;
    //       console.log("logged out");
    //       this.router.navigate(["/"]);
    //     },
    //     (error) => {
    //       // alert(error);
    //       this.toastr.error("error");
    //     }
    //   );
  }

  async connectAccount() {
    if (!(window as any).ethereum) {
      this.toastr.error(
        "Please install MetaMask first. (Add Extension of Metamask)"
      );
      return;
    }
    console.log("logging in ");
    try {
      this.isLoading = true;
      let account = await this.walletConnectService
        .connectAccount()
        .catch((Err) => { console.log("Conection err: ", Err); this.isLoading = false });
      if (account == undefined || account == null) {
        this.toastr.error("Could not connect with Metamask");
        return;
      }
      this.userdata = await this.getUserDataByWalletAddress(account).catch(
        (err) => console.log("err: ", err)
      );
      console.log("user data from firebase: ", this.userdata);
      if (
        this.userdata == undefined ||
        this.userdata == null ||
        this.userdata?.length > 0 == false
      ) {
        this.toastr.error("User Not Found! Please Ad Profile");
        this.router.navigate(["/register"]);
        this.isLoading = false;
        return;
      }
      this.userdata[0]["uid"] = this.userdata[0].id;
      this.AuhtenticateUser(this.userdata[0]);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }

  async getUserDataByWalletAddress(walletAddress) {
    let users = await this.userService.getUserByWalletAddress(walletAddress);
    return users;
  }

  AuhtenticateUser(userObj) {
    if (userObj.role == "Bidder") {
      console.log("this is bidder");
      this.isBidder = true;
    } else if (userObj.role == "Admin") {
      console.log("this is Admin");
      this.isAdmin = true;
    } else if (userObj.role == "Instructor") {
      console.log("this is Instructor");
      this.isInstructor = true;
    } else {
      // alert("invalid");
    }
    localStorage.setItem("userData", JSON.stringify(userObj));
    localStorage.setItem("metaMaskWallet", userObj?.wallet);
    localStorage.setItem("uid", userObj?.id);
  }

  async refreshBalance() {
    let account = localStorage.getItem("metaMaskWallet");
    if (account) {
      this.AccountBalance = await this.walletConnectService.getWalletBalance(
        account
      );
    }
  }
}
