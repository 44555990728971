import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  Input,
} from "@angular/core";
import axios from "axios";
import { ToastrService } from "ngx-toastr";

import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import * as firebase from "firebase";
import { BidRecordService } from "src/app/bidRecord.service";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
import { InstructorListComponent } from "../instructor-list/instructor-list.component";

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.css"],
})
export class PaymentMethodComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild("cardInfo") cardInfo: ElementRef;
  @Input() auctionId = null;
  @Input() bidId = null;
  auctionData: any;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  user = null;
  constructor(
    public router: Router,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private auctionService: AuctionService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // this.user = firebase.auth().currentUser;
    const auction = this.auctionService.getAuctionById(this.auctionId);
    if (auction) {
      this.auctionData = auction;
    }
  }

  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: "24px",
        fontFamily: "monospace",
        fontSmoothing: "antialiased",
        fontSize: "19px",
        "::placeholder": {
          color: "black",
        },
      },
    };

    this.card = elements.create("card", { style });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener("change", this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener("change", this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  async onSubmit(form: NgForm) {
    // debugger
    const { token, error } = await stripe.createToken(this.card);
    //  axios({
    //   method: "post",
    //   url: `https://us-central1-wellness-winners.cloudfunctions.net/stripePayment`,
    // }).then(
    //   async (response) => {
    //
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    if (error) {
      console.log("Something is wrong:", error);
    } else {
      if (!this.bidId) {
        // alert("Payment can not be processed at this time");
        this.toastr.error("Payment can not be processed at this time");
        return false;
      }
      await this.auctionService.updateAuction(this.auctionId, {
        isPaid: true,
        bidId: this.bidId,
        paidOn: new Date(),
      });
      this.modalService.dismissAll();

      // alert("Payment successful");
      this.toastr.success("Payment successful");
      this.router.navigate(["/bidprofile"]);
    }
  }
}
