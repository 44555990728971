import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BidRecordService } from "src/app/bidRecord.service";
import { BidRecord } from "src/app/bidRecord.model";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AuctionService } from "src/app/auction.service";
import { Auction } from "src/app/auction.model";
// import * as firebase from "firebase";
import { ToastrService } from "ngx-toastr";
import { MetaMaskWalletService } from "src/app/services/meta-mask-wallet.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  closeResult = "";

  bidHistory: BidRecord[];
  show = false;
  isBidVisible = true;
  isButtonClicked = true;
  showToggle = false;
  banner_Id = null;
  UpdateName = JSON.parse(localStorage.getItem("userData"));
  UpdateNamedata = this.UpdateName ? this.UpdateName.displayName : null;
  loginUser = this.UpdateName ? this.UpdateName.uid : null;
  auctionId = null;
  banner = null;
  buttonAShow: true;
  timeId: any;
  auctionTime: any = null;
  price: any;
  sponsor: any = null;
  bidId: null;
  // user = null;
  auction = null;
  canBid = false;
  bid: any;
  valueUrl: any;
  quantity: number = 1;
  quickBid = 1;

  constructor(
    private toastr: ToastrService,
    public router: Router,
    private bidRecordService: BidRecordService,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private auctionService: AuctionService,
    private metaMaskService: MetaMaskWalletService
  ) {}

  isInPaymentMode: boolean = false;
  Auctions: any[];
  timeDiff = null;

  selectedBid = null;
  AuctionPaymentAmount: number = 0;

  open(content, bidId, auctionPaymentAmount?) {
    this.AuctionPaymentAmount = auctionPaymentAmount;
    this.bidId = bidId;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.auctionId = this.route.snapshot.params.auctionId;
    this.bid = Number(this.route.snapshot.params.bid);
    this.valueUrl = this.bid;
    this.getBidHistory();
    // this.user = firebase.auth().currentUser;
    this.getAuction();
    if (!localStorage.getItem("userData")) {
      this.showToggle = true;
    } else {
      this.showToggle = false;
    }
  }

  ngOnDestroy(): void {
    if (this.timeId) {
      clearInterval(this.timeId);
    }
  }

  async getAuction() {
    const auction = await this.auctionService.getAuctionById(this.auctionId);
    if (auction) {
      this.auction = auction;
      this.canBid = true;
      if (this.auction) {
        this.handleTime();
        const that = this;
        this.timeId = setInterval(function () {
          that.handleTime();
        }, 1000);
      }
    }
  }

  async getAuctionCollection() {
    let tempData = [];
    for (let item of this.Auctions) {
      const auction = await this.auctionService.getAuctionById(this.auctionId);
      tempData.push({ ...item, auction });
    }
    this.Auctions = tempData;
  }

  async getBidHistorywithAwait() {
    let loggedUser = JSON.parse(localStorage.getItem("userData"));
    this.loginUser = loggedUser?.uid;
    this.bidHistory = await this.bidRecordService
      .getBidHistoryByUserAndAuction(this.loginUser, this.auctionId)
      .catch((err) => {
        console.log("err: ", err);
      });
  }

  async getBidHistory() {
    let loggedUser = JSON.parse(localStorage.getItem("userData"));
    this.loginUser = loggedUser?.uid;
    await this.bidRecordService
      .getBidHistoryByUser(this.loginUser, this.auctionId)
      .subscribe(async (data) => {
        this.bidHistory = data.map((e) => {
          return {
            id: e.payload.doc.id,
            ...(e.payload.doc.data() as {}),
          } as BidRecord;
        });
        if (
          this.bidHistory.find(
            (el) =>
              el.userId == JSON.parse(localStorage.getItem("userData")).uid
          )
        ) {
          this.isBidVisible = false;
        }
      });
  }

  update(index) {
    this.isButtonClicked = false;
    this.selectedBid = { ...this.bidHistory[index] };
  }

  create(bidRecord: number) {
    if (!this.auctionId || !this.auctionId) {
      return false;
    }
    if (!localStorage.getItem("userData")) {
      this.toastr.info("Please Connect Your MetaMask Wallet");
      // this.router.navigate([`/login/${this.auctionId}/${bidRecord}`]);
      // this.show = true;
      return true;
    } else {
      const userId = JSON.parse(localStorage.getItem("userData"));
      this.bidRecordService.createBidHistory({
        bidAmount: Number(bidRecord),
        userId: userId.uid,
        name: userId.userName,
        auctionId: this.auctionId,
        quantity: this.quantity,
      });
      // alert("Bid created successfully");
      this.toastr.success("Bid created successfully");
      this.getBidHistory();
      return true;
    }
  }

  async updateAmount() {
    await this.bidRecordService.updateBidHistory({ ...this.selectedBid });
    // alert("Bid updated successfully");
    this.toastr.success("Bid updated successfully");
    this.selectedBid = null;
    this.isButtonClicked = true;
    await this.getBidHistory();
    return true;
  }

  async handleQuickBid(auction, amount?: number) {
    // debugger
    if (!localStorage.getItem("userData")) {
      this.toastr.info("Please Connect Your MetaMask Wallet");
      return true;
    }
    await this.getBidHistorywithAwait().catch((err) =>
      console.log("err in bid history : ", err)
    );
    const now = new Date().getTime();
    let sec = auction.time.seconds * 1000;
    let time = new Date(sec).getTime();
    const distance = time - now;
    if (distance < 0) {
      this.toastr.error("Bid is expired");
      return false;
    }
    if (this.bidHistory?.length > 0) {
      let loggedUser = JSON.parse(localStorage.getItem("userData"));
      for (let i = 0; i < this.bidHistory.length; i++) {
        if (this.bidHistory[i].userId == loggedUser.id) {
          this.bidHistory[i].bidAmount = amount;
          this.selectedBid = { ...this.bidHistory[i] };
          await this.updateAmount();
          return;
        }
      }
    }
    if (!localStorage.getItem("userData")) {
      this.toastr.info("Please Connect Your MetaMask Wallet");
      return false;
    } else {
      const userId = JSON.parse(localStorage.getItem("userData"));
      let bid = await this.bidRecordService.createBidHistory({
        bidAmount: Number(amount),
        userId: userId.uid,
        name: userId.userName,
        auctionId: this.auctionId,
        quantity: this.quantity,
      });
      this.toastr.success("Bid successfully created");
      await this.getBidHistory();
      return false;
    }
  }

  handleAddPayment() {
    this.isInPaymentMode = true;
  }

  handleDistance(distance) {
    if (distance < 0) {
      let demo = document.getElementById("demo");
      if (demo) {
        demo.innerHTML = "EXPIRED";
      }
      let submit = document.getElementById("Sub");
      if (submit) {
        submit.classList.add("d-none");
      }
      let btn = document.getElementById("btn-payment");
      if (btn) {
        btn.classList.remove("d-none");
      }
      let subbtn = document.getElementById("btn-update");
      if (subbtn) {
        subbtn.style.display = "none";
      }
    }
  }

  handleTime() {
    if (!this.auction.time) {
      return "";
    }
    const countDownDate = this.auction.time.seconds * 1000;
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let demo = document.getElementById("demo");
    if (demo) {
      demo.innerHTML =
        days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      this.handleDistance(distance);
    }
  }

  // handleTime() {
  //   if (!this.auction.time) {
  //     return "";
  //   }
  //   const countDownDate = this.auction.time.seconds * 1000;
  //   const now = new Date().getTime();
  //   const distance = countDownDate - now;
  //   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   let demo = document.getElementById("demo");
  //   if (demo) {
  //     demo.innerHTML = minutes + "m " + seconds + "s ";
  //     // days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  //     this.handleDistance(distance);
  //   }
  // }
  handleRegister(bid) {
    this.router.navigateByUrl(`/register/${this.auctionId}/${bid}`);
  }

  isLoadingPayment: boolean = false;

  async PayWithMetamask(Amount, bidId) {
    this.isLoadingPayment = true;
    this.toastr.info("Payment is in progress please stay on page until payment is done")
    let EHTRate = await this.convertUSDToETH();
    let updatedAmount = Amount * EHTRate;
    let accountAddress = JSON.parse(localStorage.getItem("userData"))?.wallet;
    if (accountAddress) {
      let isPaymentFaild = false;
      let donated = await this.metaMaskService
        .donate(accountAddress, updatedAmount)
        .catch((err) => {
          isPaymentFaild = true;
          this.toastr.error("Payment faild " + err.message);
          this.isLoadingPayment = false;
        });
      if (!isPaymentFaild) {
        await this.auctionService.updateAuction(this.auctionId, {
          isPaid: true,
          bidId: bidId,
          paidOn: new Date(),
        });
        this.toastr.success("Payment succesfully done");
        this.isLoadingPayment = false;
        this.router.navigate(["/bidprofile"]);
      }
    }
  }

  async convertUSDToETH() {
    let ETHRate = await this.metaMaskService
      .GetETHExchangeRate()
      .catch((err) => this.toastr.error("err in getting ETH rate"));
    return parseFloat(ETHRate.data.rates.ETH);
  }
}
