<div class="container-main mt-4 mb-4 mx-auto">
<div class="section-img">
    <img src="../../../assets/Bundle/AA-Bundle_ARC-files_AA-Bidding-LP.jpg" />
    <div class="text-main">
        <img src="../../../assets/Bundle/AA-Bundle_ARC-files_AA-Headline.png" />
    </div>
</div>
<div class="container-card">
<div class="card-main col-lg-4 col-md-4 col-sm-4 p-0">
    <div class="card-img">
        <img src="../../../assets/Bundle/AA-Bundle_ARC-files_AA-B1_Fotor.png" />
    </div>
    <div class="list-main">
        <ul>
            <li>www.wellness-winner.web.app</li>
            <li>Replicated for LL</li>
            <li>Replicated for AA</li>
            <li>Replicated for WW</li>
        </ul>
    </div>
    <div class="btn-main">
        <button>Get Started</button>
    </div>
</div>
</div>
</div>
