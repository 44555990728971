import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { USER } from "../../constants";
import { take } from "rxjs/operators";
import { MetaMaskWalletService } from "src/app/services/meta-mask-wallet.service";

@Component({
  selector: "app-sponsors",
  templateUrl: "./sponsors.component.html",
  styleUrls: ["./sponsors.component.css"],
})
export class SponsorsComponent implements OnInit {
  sponsors;
  MyNFTS = [];
  loadingNFT: boolean = false;
  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private NFTService: MetaMaskWalletService,
    private toastr: ToastrService
  ) {
    const uid = localStorage.getItem("uid");
    this.firestore
      .collection("sponsors")
      .snapshotChanges()
      .subscribe((data) => {
        this.sponsors = data;
        for (let sponsor of this.sponsors) {
          sponsor.score = sponsor.payload.doc
            .data()
            ["instructorScores"].filter((obj) => {
              return obj.uid == uid;
            });
        }
      });
  }

  expiryCodeDocId;
  ngOnInit(): void {
    const docId = localStorage.getItem("expiryCode");
    this.expireCodeCheck(docId);
    // this.getAllNFTS();
  }

  expireCodeCheck(docId) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("expiryCode", (ref) =>
          ref.where("expiry_code", "==", docId)
        )
        .valueChanges()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length == 0) {
            this.router.navigateByUrl("/complete");
          } else {
            this.expiryCodeDocId = data[0];
            if (this.expiryCodeDocId.expired == true) {
              this.router.navigateByUrl("/expired");
            }
          }
        });
    });
  }

  // handleSponsorClick(item) {
  //   const score = item.score[0].score;
  //   const rewardPercentage = item.payload.doc.data().rewardPercentage;
  //   const rewardCode = item.payload.doc.data().rewardCode;
  //   ;
  //   const instructorScore = item.payload.doc.data().instructorScores
  //   console.log("instructo store" + JSON.stringify(instructorScore))
  //   if (score > 0) {
  //     const uid = localStorage.getItem("uid");
  //     let sponsorIndex = instructorScore.findIndex((obj => obj.uid == uid));
  //     console.log("this is the index" + JSON.stringify(instructorScore[sponsorIndex]))
  //     instructorScore[sponsorIndex].score = --(instructorScore[sponsorIndex].score)
  //     return new Promise<any>((resolve, reject) => {
  //       this.firestore
  //         .collection('sponsors')
  //         .doc(`${item.payload.doc.id}`)
  //         .update({instructorScores: instructorScore })
  //         .then(res => {
  //           this.router.navigateByUrl(`/won/${rewardCode}/${rewardPercentage}`);
  //           resolve();
  //         }, err => reject(err));
  //     })
  //   } else {
  //     this.router.navigateByUrl(`/lost`);
  //   }
  // }

  // NFTS
  async getAllNFTS() {
    this.loadingNFT = true;
    try {
      this.loadingNFT = true;
      this.MyNFTS = await this.NFTService.getList();
      this.getNFTImagesPath();
      this.loadingNFT = false;
    } catch (error) {
      this.loadingNFT = false;
    }
  }

  getNFTImagesPath() {
    for (let i = 0; i < this.MyNFTS?.length; i++) {
      if (this.MyNFTS[i].image)
        this.MyNFTS[i]["image"] = this.MyNFTS[i].image.replace(
          "ipfs.infura.io",
          "ipfs.io"
        );
    }
    console.log("my nfts data with right image path : ", this.MyNFTS);
  }

  async GiftNFT(item) {
    console.log("Going to gift this item : ", item);
    let data = await this.NFTService.getDataByTokenId(item?.tokenId);
    console.log("data by Token id in func: ", data);
    let isPaymentFaild = false;
    let Trasnfered = await this.NFTService.NFTTransferToVisitor(item).catch(
      (err) => {
        isPaymentFaild = true;
        this.toastr.error("Payment faild " + err.message);
      }
    );
    if (!isPaymentFaild) {
      this.toastr.success("Transfered succesfully");
      this.router.navigateByUrl(`/won/${item.name}/${item.price}`);
      await this.getAllNFTS();
    }
    console.log("trasnfered: ", Trasnfered);
  }

  // Connect Account
  connectedAccount: string = undefined;
  async connectAccount() {
    if (!(window as any).ethereum) {
      this.toastr.error(
        "Please install MetaMask first. (Add Extension of Metamask)"
      );
      return;
    }
    this.connectedAccount = await this.NFTService.connectAccount().catch(
      (Err) => {
        console.log("Conection err: ", Err);
      }
    );
    if (this.connectedAccount) {
      this.toastr.success("Metamask Account Connected");
      this.getAllNFTS()
    }
  }
}
