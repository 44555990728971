<!-- <app-admin-layout *ngIf="!showToggle"></app-admin-layout> -->
<ng-template #content let-modal>
  <!-- <app-payment-method [auctionId]="auctionId" [bidId]="bidId"></app-payment-method> -->
</ng-template>

<div class="banner-heading mt-3 p-4" *ngIf="canBid">
  <!-- <button class="cancle-btn">Cancle</button> -->
  <div id="cancle-btn">
    <a routerLink="/auction-list"
      ><i class="fa fa-arrow-left" aria-hidden="true"></i
    ></a>
  </div>

  <!-- <div class="cancle-btn"><a routerLink="/auction-list"></div> -->
  <div class="banner-btn">
    <button class="active-btn">Active</button>
  </div>
</div>
<div id="main" [ngClass]="canBid ? 'container mt-1 mb-3' : 'container-no-bid'">
  <div class="banner-img1">
    <div class="mbl-img">
      <img
        src="../../../assets/Auctions/AA-Bidding_assets_ARC-files_Mobile-WW.png"
      />
    </div>
    <h1>650X190px</h1>
  </div>
  <div class="banner-details" *ngIf="canBid">
    <div id="span">
      <strong>Current Price </strong>
      <span>${{ auction.price }}</span>
      <!-- <small> No Reserve</small> -->
    </div>
    <div class="banner-details-container">
      <div class="buttons">
        <div *ngIf="isBidVisible" class="First-b mt-1 mb-3">
          <button class="btn-price" (click)="handleQuickBid(auction, 1)">
            Quick Bid ${{ auction.price }}
          </button>
        </div>

        <div *ngIf="!isBidVisible" class="First-b mt-1 mb-3">
          <button class="btn-price">
            Quick Bid ${{ auction.price }}
          </button>
        </div>

        <div class="orr">
          <img
            src="../../../assets/Auctions/AA-Bidding_assets_ARC-files_OR-WW.png"
            alt=""
          />
        </div>

        <span class="number-wrapper">
          <input
            type="number"
            class=""
            [(ngModel)]="quantity"
            id="quantity"
            placeholder="Select Quantity"
            min="1"
            max="10"
        /></span>

        <!-- <span class="number-wrapper">
          <input
            type="number"
            class="mt-2"
            [ngModel]="quantity"
            id="quantity"
            placeholder="Select Quantity"
            min="1"
            max="10"
        /></span> -->

        <!-- <div *ngIf="isBidVisible" id="Sub" class="row mt-3">
          <div class="col-7">
            <label class="sr-only" for="inlineFormInputGroup">Bid</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                type="number"
                #bid
                name="bid"
                [(ngModel)]="valueUrl"
                class="form-control"
                id="inlineFormInputGroup"
                placeholder="Bid"
              />
            </div>
          </div>
          <div class="col-5">
            <button class="submit-btn" (click)="create(bid.value)" id="SubBtn">
              Submit Bid
            </button>
          </div>
        </div> -->

        <div *ngIf="!isButtonClicked" class="row mt-3">
          <div class="col-7">
            <label class="sr-only" for="inlineFormInputGroup">Bid</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                type="number"
                [(ngModel)]="selectedBid.bidAmount"
                class="form-control"
                placeholder="Bid"
              />
            </div>
          </div>
          <div class="col-5">
            <button class="submit-btn" (click)="updateAmount()" id="btn1">
              Save
            </button>
          </div>
        </div>

        <!-- <div class="orr mt-2">
          <img
            src="../../../assets/Auctions/AA-Bidding_assets_ARC-files_OR-WW.png"
            alt=""
          />
        </div> -->

        <!-- <div class="row mt-2">
          <div class="col-5">
            <hr class="solid" />
          </div>
          <div class="col-2">
            <i class="or">OR</i>
          </div>
          <div class="col-5">
            <hr class="solid" />
          </div>
        </div> -->

        <div class="Final-bid mt-2 mb-2">
          <button
            class="btn"
            (click)="handleQuickBid(auction, quantity)"
          >
            Buy Now ${{ quantity }}
          </button>
        </div>

        <!-- <div class="data-btn">
          <button>Data</button>
        </div> -->

        <div class="data-btn">
          <div class="card shadow">
            <p *ngIf="!bidHistory?.length" class="p-2 text-center pt-3">
              No data to show
            </p>
            <div *ngIf="bidHistory?.length" class="table-responsive">
              <table
                class="table table-striped"
                style="width: 100%"
                *ngIf="bidHistory?.length"
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col" colspan="3">Maximum Bid ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bidRecord of bidHistory; index as i">
                    <td>
                      {{ bidRecord.name || "N/A" }}
                    </td>
                    <td>
                      {{ bidRecord.bidAmount }}
                    </td>

                    <td>
                      <button
                        *ngIf="bidRecord.userId == loginUser"
                        type="button"
                        title="Edit bid"
                        id="btn-update"
                        class="btn-bid"
                        (click)="update(i)"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        *ngIf="bidRecord.userId == loginUser && i == 0"
                        type="button"
                        id="btn-payment"
                        title="Procced to payment"
                        class="btn-bid d-none"
                        [disabled]="isLoadingPayment"
                        (click)="PayWithMetamask(bidRecord.bidAmount,bidRecord.id)"
                      >
                        $ <img *ngIf="isLoadingPayment" width="30" height="30" src="./../../../assets/Loader1.gif" alt="">
                      </button>
                      <!-- <button *ngIf="bidRecord.userId == loginUser && i == 0" type="button" id="btn-payment"
                        title="Procced to payment" class="btn-bid d-none" (click)="open(content, bidRecord.id)">
                        $
                      </button> -->
                      <!-- <button type="button" id="btn-payment" title="Procced to payment" class="btn-bid "
                      (click)="PayWithMetamask(bidRecord.bidAmount)">
                        $
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div *ngIf="!isBidVisible" class="row">
          <div class="col-12">
            <br />
            <label class="para">You have posted a bid on this auction</label>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="over1" *ngIf="canBid">
      <span class="mr-2" style="font-weight: 200; font-size: 16px"
        ><b>Time Left:</b></span
      >
      <span id="demo" style="font-weight: 900; font-size: 18px">{{
        handleTime()
      }}</span>
    </div>
    <div class="over" *ngIf="!canBid">
      <h5>No auction available yet</h5>
    </div>
  </div>
</div>
