import { UserService } from './../../user.service';
import { Component, Input, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
// import * as firebase from 'firebase';
// import {getUser} from '../../services/firebase.service';
import * as CONSTANTS from '../../constants';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
// import * as firebase from "firebase";
// import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {

  securityForm: any;
  constructor(private usrSrv: UserService, private firestore: AngularFirestore, private toastr: ToastrService, private fb: FormBuilder) {
    this.initForm()
  }

  initForm() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.securityForm = this.fb.group({
      userName: [null, Validators.required],
      email: [this.user?.email]
    })
  }

  @ViewChild('displayName', { static: true }) displayName: ElementRef;
  @ViewChild('email', { static: true }) email: ElementRef;
  @ViewChild('confirmPassword', { static: true }) confirmPassword: ElementRef;
  @ViewChild('Password', { static: true }) Password: ElementRef;
  userName: string = "";
  user;
  magicLink = '';
  activeTabId = 'personal';

  base64textString;

  async ngOnInit() {
    // console.log(await getUser());
    this.user = JSON.parse(localStorage.getItem("userData"));
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    this.securityForm.get('userName').patchValue(this.user.userName)
  }

  async handleSubmit() {
    try {
      const email = this.securityForm.value.email;
      const displayName = this.securityForm.value.userName;
      if (displayName == undefined || displayName == null || displayName == '') {
        this.toastr.error("Please Enter Name");
        return
      }
      let user = JSON.parse(localStorage.getItem("userData"));
      console.log("user from localstorage: ",user);
      
      let loggedInUserData = await this.getUserById(user).catch(err => { console.log(err) });
      console.log("user from database: ", loggedInUserData);
      let updateUser = {}
      if (loggedInUserData) {
        updateUser = {
          date: loggedInUserData.date,
          email: loggedInUserData.email,
          role: loggedInUserData.role,
          id: loggedInUserData.id,
          userName: displayName,
          wallet: loggedInUserData.wallet
        }
      }
      console.log("user before updating", updateUser);
      this.usrSrv.updateUser(updateUser).then(() => {
        localStorage.setItem('userData', JSON.stringify(updateUser));
        this.toastr.success("Profile updated successfully");
      })
    } catch (error) {
      console.log("user from database: ",error);
    }


  }

  async getUserById(user) {
    const userData = await this.usrSrv.getUserByID(user.id).catch(err => console.log("error in getting user by emaiil: ", err))
    return userData
  }

  // resetPassword() {
  //   const Password = this.Password.nativeElement.value;
  //   const newPassword = this.confirmPassword.nativeElement.value;
  //   const user = firebase.auth().currentUser;
  //   if (Password == newPassword) {
  //     user.updatePassword(newPassword).then(function() {
  //       console.log('password updated successfully');
  //       this.toastr.success('password updated');
  //     }).catch(function(error) {
  //       console.log(error);
  //     });
  //   } else {
  //     // alert('Password does not match');
  //     this.toastr.error('Password does not match');
  //   }
  //   }

  // copyMagicLink() {
  //   const selBox = document.createElement('textarea');
  //   selBox.style.position = 'fixed';
  //   selBox.style.left = '0';
  //   selBox.style.top = '0';
  //   selBox.style.opacity = '0';
  //   selBox.value = this.magicLink;
  //   document.body.appendChild(selBox);
  //   selBox.focus();
  //   selBox.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(selBox);
  //   this.magicLink = '';
  // }

  // async generateLink() {
  //   const randomString = Math.random().toString(36).substr(2, 10);
  //   console.log('randomString', randomString);
  //   this.user = JSON.parse(localStorage.getItem("userData"));
  //   const uid = this.user?.uid;
  //   // alert(uid);
  //   await this.createExpiryCode({expiry_code: randomString, expired: false, uid});
  //   const parsedUrl = new URL(window.location.href);
  //   const baseUrl = parsedUrl.origin;
  //   this.magicLink = baseUrl + '/intro/' + this.user?.uid + '/' + randomString;
  // }

  // async createExpiryCode(data) {
  //   console.log("user", data);
  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore.collection('expiryCode')
  //       .add(data)
  //       .then(res => {
  //         console.log(res);
  //         resolve(res);
  //       }, err => {console.log(err); });
  //   });
  // }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }


  setActiveTab(tabId: string) {
    this.activeTabId = tabId;
  }
}
