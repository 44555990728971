import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-intro',
  templateUrl: './expired.html',
  styleUrls: ['./expired.css']
})
export class Expired implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }

}
