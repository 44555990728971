<!-- for website layout -->

<div class="header-main">
  <div class="logo">
    <img
      src="../../../assets/Auctions/AA-Bundle&Bidding_ARC-files_AA-Bidding_Fotor.png"
      alt=""
    />
  </div>
  <ul
    [ngClass]="{ ConnectWalletRight: !isAdmin && !isInstructor && !isBidder }"
  >
    <div class="mt-4 px-2" *ngIf="isAdmin"></div>
    <li (click)="toggleSidebar()" *ngIf="isAdmin || isInstructor">
      <a routerLink="/profile" class="profile">Profile</a>
    </li>
    <div class="mt-4 px-2" *ngIf="isAdmin"></div>
    <li (click)="toggleSidebar()" *ngIf="isAdmin">
      <a routerLink="/list-instructors" class="profile">Instructors</a>
    </li>
    <div class="mt-4 px-2" *ngIf="isInstructor"></div>
    <li (click)="toggleSidebar()" *ngIf="isInstructor">
      <a routerLink="/my-banners" class="profile">My Banners</a>
    </li>
    <!-- <div class="mt-4 px-2" *ngIf="isInstructor"></div>
    <li (click)="toggleSidebar()" *ngIf="isInstructor">
      <a routerLink="/my-banners"  class="profile"
        >My Banners</a
      >
    </li> -->
    <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <li (click)="toggleSidebar()" *ngIf="isBidder">
      <a routerLink="/auction-list" class="profile">Home</a>
    </li>
    <!-- <div class="mt-4 px-2"></div>

    <li>
      <a routerLink="/list-instructors" class="instructor">Instructors</a>
    </li> -->
    <!-- <div class="mt-4 px-2" *ngIf="isInstructor"><hr /></div>
    <li *ngIf="isInstructor">
      <a routerLink="/my-banners" (click)="toggleSidebar()" class="profile"
        >My Banners</a
      >
    </li> -->
    <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <li *ngIf="isBidder">
      <a routerLink="/security" (click)="toggleSidebar()" class="profile"
        >Profile</a
      >
    </li>

    <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <li *ngIf="isBidder">
      <a routerLink="/bidprofile" (click)="toggleSidebar()" class="profile"
        >Bidder Profile</a
      >
    </li>
    <!-- <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <div class="mt-4 px-2" *ngIf="isAdmin"></div> -->
    <li
      style="border: 1px solid white; border-radius: 25px; padding: 3px"
      *ngIf="!isBidder && !isAdmin && !isInstructor && !isLoading"
      (click)="connectAccount()"
    >
      <a style="cursor: pointer; margin: 18px 21px !important">
        Connect Wallet</a
      >
    </li>
    <li
      style="border: 1px solid white; border-radius: 25px; padding: 3px"
      *ngIf="!isBidder && !isAdmin && !isInstructor && isLoading"
    >
      <a style="cursor: pointer; margin: 18px 21px !important">
         <span ><img width="25px" src="./../../../assets/Loader1.gif" alt=""></span> </a
      >
    </li>

    <div class="mt-4 px-2" *ngIf="isBidder || isAdmin || isInstructor"></div>
    <li *ngIf="isBidder || isAdmin || isInstructor" (click)="Logout()">
      <a style="cursor: pointer"> Logout</a>
    </li>
  </ul>
</div>

<!-- for mobile -->
<div class="sidenav" *ngIf="showSidebar">
  <a routerLink="/">
    <img
      src="../../../assets/images/logo.png"
      width="85%"
      class="mx-auto d-block mb-3"
    />
  </a>
  <ul>
    <div class="mt-4 px-2" *ngIf="isAdmin"></div>
    <li (click)="toggleSidebar()" *ngIf="isAdmin">
      <span class="mr-2"><img class="" src="assets/Profile_icon.png" /></span>
      <a routerLink="/profile" class="profile">Profile</a>
    </li>
    <div class="mt-4 px-2" *ngIf="isAdmin"><hr /></div>
    <li *ngIf="isAdmin">
      <span class="mr-2"><img class="" src="assets/Security_1.png" /></span>
      <a routerLink="/my-banners" (click)="toggleSidebar()" class="profile"
        >My Banners</a
      >
    </li>
    <div class="mt-4 px-2" *ngIf="isAdmin"><hr /></div>
    <div class="mt-4 px-2" *ngIf="isBidder"><hr /></div>
    <li (click)="toggleSidebar()" *ngIf="isBidder">
      <span class="mr-2"><img class="" src="assets/Profile_icon.png" /></span>
      <a routerLink="/auction-list" class="profile">Home</a>
    </li>
    <!-- <div class="mt-4 px-2"></div>

    <li>
      <a routerLink="/list-instructors" class="instructor">Instructors</a>
    </li> -->
    <div class="mt-4 px-2" *ngIf="isInstructor"><hr /></div>
    <li *ngIf="isInstructor">
      <span class="mr-2"><img class="" src="assets/Security_1.png" /></span>
      <a routerLink="/my-banners" (click)="toggleSidebar()" class="profile"
        >My Banners</a
      >
    </li>
    <div class="mt-4 px-2" *ngIf="isBidder"><hr /></div>
    <li *ngIf="isBidder">
      <span class="mr-2"><img class="" src="assets/Profile_icon.png" /></span>
      <a routerLink="/security" (click)="toggleSidebar()" class="profile"
        >Profile</a
      >
    </li>

    <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <li *ngIf="isBidder">
      <span class="mr-2"><img class="" src="assets/Security_1.png" /></span>
      <a routerLink="/bidprofile" (click)="toggleSidebar()" class="profile"
        >Bidder Profile</a
      >
    </li>
    <div class="mt-4 px-2" *ngIf="isBidder"></div>
    <div class="mt-4 px-2" *ngIf="isAdmin"></div>
    <li (click)="Logout()" *ngIf="isAdmin || isBidder || isInstructor">
      <span class="mr-2"><img class="" src="assets/Back.png" /></span>
      <a style="cursor: pointer"> Logout</a>
    </li>
    <li
      *ngIf="!isBidder && !isAdmin && !isInstructor"
      (click)="connectAccount()"
    >
      <span class="mr-2"><img class="" src="assets/Back.png" /></span>
      <a style="cursor: pointer"> Connect Wallet</a>
    </li>
  </ul>
  <div class="mt-4 px-2" *ngIf="isAdmin"><hr /></div>

  <div class="row justify-content-center text-center">
    <div class="col-2 text-center">
      <div class="mt-4 mb-4">
        <a class="profile mx-auto"
          ><span class="mx-auto" (click)="toggleSidebar()"
            ><img class="" src="assets/Asset 9.png" /></span
        ></a>
      </div>
    </div>
  </div>
</div>
<div
  class="navbar navbar-expand-lg fixed-top navbar-light"
  style="z-index: 90 !important"
>
  <span
    (click)="toggleSidebar()"
    class="navbar-toggler-icon float-right ml-auto"
  ></span>
  <span> </span>
</div>
<div class="instructor-list">
  <router-outlet></router-outlet>
</div>
