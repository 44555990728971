import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flow-sucess',
  templateUrl: './flow-sucess.component.html',
  styleUrls: ['./flow-sucess.component.css']
})
export class FlowSucessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
