<app-admin-layout></app-admin-layout>
<!-- for mobile -->
<div class="container" id="mobile">
  <br />
  <div class="card">
    <h1>Bid Profile</h1>

    <ngb-tabset #t="ngbTabset">
      <ngb-tab
        id="tab-selectbyid1"
        title="Information"
        style="background-color: #262c34"
      >
        <ng-template ngbTabContent style="background-color: #262c34">
          <div>
            <br />
            <label for="name"> Name: {{ UpdateName.displayName }}</label> <br />
            <label> Email: </label>
            <label for="email" id="email"> {{ UpdateName.email }} </label>
            <br />
          </div>

          <label
            for="tableData"
            id="tableData"
            *ngIf="!BidHistory || !BidHistory.length"
            ><b> You have no bids yet</b></label
          >
          <br />
          <div style="overflow-x: auto">
            <table
              class="table table-striped"
              *ngIf="BidHistory && BidHistory.length"
            >
              <thead>
                <tr>
                  <th scope="col">Bid Id</th>

                  <th scope="col">Paid On</th>
                  <th scope="col">Price</th>
                  <th scope="col">Time</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let bidHistory of BidHistory; index as i">
                <tr *ngIf="bidHistory.userId == loginUser">
                  <td>{{ bidHistory.id }}</td>
                  <td>
                    {{ toDateString(bidHistory.auction?.paidOn?.seconds) }}
                  </td>
                  <td>
                    {{ bidHistory?.bidAmount * (bidHistory?.quantity || 1) }}
                  </td>
                  <td>
                    {{ toDateString(bidHistory.auction?.time?.seconds * 1000) }}
                  </td>
                  <td>
                    <button
                      *ngIf="bidHistory.auction?.isPaid === true"
                      class="mt-1"
                      (click)="
                        getSponsor(
                          bidHistory.auction?.bannerId,
                          bidHistory.auctionId,
                          bidHistory
                        )
                      "
                    >
                      Upload Banner
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab
        id="tab-selectbyid2"
        *ngIf="auctionId"
        title="Media"
        style="background-color: #262c34"
      >
        <ng-template
          ngbTabContent
          *ngFor="let bidHistory of BidHistory; index as i"
        >
          <div class="main-content">
            <br />
            <span style="color: red" *ngIf="message">{{ message }}</span>
            <br />
            <input
              class="uploadBtn"
              #file
              type="file"
              accept="image/*"
              (change)="upload($event, file.files)"
            />
            <br />
            <br />
            <p>Current Uploaded Image</p>
            <!-- <img [src]="{{https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg}}" height="200" *ngIf="ImagePreview"> -->
            <div class="img-main">
              <img
                [src]="ImagePreview"
                id="img-siz"
                alt="image"
                *ngIf="ImagePreview"
              />
            </div>
            <br />
            <br />
            <br />
            <div class="img-container">
              <img [src]="imageShow" id="img-siz" *ngIf="imgURL" />
            </div>
            <br />
            <br />
            <div class="codeInputs1">
              <label>Reward Code: </label>
              <input [(ngModel)]="reward.code" />
            </div>
            <div class="codeInputs2">
              <label>Reward Percentage: </label>
              <input [(ngModel)]="reward.percentage" type="number" />
            </div>
            <button
              *ngIf="imageShow"
              [disabled]="isUploading"
              (click)="CreateAndLitNFt()"
              class="uploadButton mt-2"
            >
              {{ isUploading ? "Saving..." : "Save" }}
            </button>
            <!-- <button
              *ngIf="imageShow"
              [disabled]="isUploading"
              (click)="uploadImage(bidHistory)"
              class="uploadButton mt-2"
            >
              {{ isUploading ? "Saving..." : "Save" }}
            </button> -->
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  <br />
</div>

<!-- for Desktop -->
<div class="container" id="desktop">
  <br />
  <div class="card">
    <h1>Bid Profile</h1>

    <ngb-tabset #t="ngbTabset">
      <ngb-tab
        id="tab-selectbyid1"
        title="Information"
        style="background-color: #262c34"
      >
        <ng-template ngbTabContent style="background-color: #262c34">
          <div>
            <br />
            <label for="name"> Name: {{ UpdateName.displayName }}</label> <br />
            <label> Email: </label>
            <label for="email" id="email"> {{ UpdateName.email }} </label>
            <br />
          </div>

          <label
            for="tableData"
            id="tableData"
            *ngIf="!BidHistory || !BidHistory.length"
            ><b> You have no bids yet</b></label
          >
          <br />
          <div style="overflow-x: auto">
            <table
              class="table table-striped"
              *ngIf="BidHistory && BidHistory.length"
            >
              <thead>
                <tr>
                  <th scope="col">Bid Id</th>

                  <th scope="col">Paid On</th>
                  <th scope="col">Price</th>
                  <th scope="col">Time</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let bidHistory of BidHistory; index as i">
                <tr *ngIf="bidHistory.userId == loginUser">
                  <td>{{ bidHistory.id }}</td>
                  <td>
                    {{ toDateString(bidHistory.auction?.paidOn?.seconds) }}
                  </td>
                  <td>
                    {{ bidHistory?.bidAmount * (bidHistory?.quantity || 1) }}
                  </td>
                  <td>
                    {{ toDateString(bidHistory.auction?.time?.seconds * 1000) }}
                  </td>
                  <td>
                    <button
                      class="mt-1"
                      (click)="
                        getSponsor(
                          bidHistory.auction?.bannerId,
                          bidHistory.auctionId,
                          bidHistory
                        )
                      "
                      *ngIf="
                        toDateString(bidHistory.auction?.paidOn?.seconds) !=
                          null &&
                        toDateString(bidHistory.auction?.paidOn?.seconds) !=
                          undefined
                      "
                    >
                      Upload Banner
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab
        id="tab-selectbyid2"
        *ngIf="auctionId"
        title="Media"
        style="background-color: #262c34"
      >
        <ng-template
          ngbTabContent
          *ngFor="let bidHistory of BidHistory; index as i"
        >
          <div class="main-content">
            <br />
            <span style="color: red" *ngIf="message">{{ message }}</span>
            <br />
            <input
              class="uploadBtn"
              #file
              type="file"
              accept="image/*"
              (change)="upload($event, file.files)"
            />
            <br />
            <br />
            <p style="color: white; text-align: center; font-weight: 500">
              Current Uploaded Image
            </p>
            <!-- <img [src]="{{https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg}}" height="200" *ngIf="ImagePreview"> -->
            <div class="img-main">
              <img
                [src]="ImagePreview"
                id="img-siz"
                alt="image"
                *ngIf="ImagePreview && imageShow == undefined"
              />

              <img [src]="imageShow" id="img-siz" *ngIf="imgURL" />
            </div>
            <!-- <br />
            <br />
            <br /> -->
            <!-- <div class="img-container">
                <img [src]="imageShow" id="img-siz" *ngIf="imgURL"></div> -->
            <br />
            <br />
            <div class="codeInputs-main">
              <div class="codeInputs1">
                <label>Reward Code: </label>
                <input [(ngModel)]="reward.code" />
              </div>
              <div class="codeInputs2">
                <label>Reward Percentage: </label>
                <input [(ngModel)]="reward.percentage" type="number" />
              </div>
            </div>
            <!-- <div class="codeInputs2">
                  <label>Reward Percentage: </label>
                  <input [(ngModel)]="reward.percentage" type="number" />
                </div> -->
            <div class="btn-main">
              <button
                *ngIf="imageShow"
                [disabled]="isLoading"
                (click)="CreateAndLitNFt()"
                class="uploadButton mt-2"
              >
                {{ isLoading ? "Saving..." : "Save" }}
              </button>
              <!-- <button
                *ngIf="imageShow"
                [disabled]="isUploading"
                (click)="uploadImage(bidHistory)"
                class="uploadButton mt-2"
              >
                {{ isUploading ? "Saving..." : "Save" }}
              </button> -->
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>

  <br />
</div>
